@import '../../index.scss';

.order_card_wrapper {
  display: flex;
  flex-direction: column;
  margin: 6px 37px 18px 13px;
  min-height: 250px;
  position: relative;
  border-radius: 18px;
  box-shadow: 1px 6px 39px 1px rgba(0, 0, 0, 0.14);

  .order_card_container {
    .swiper_wrap {
      position: relative;
      height: 50%;

      .saree_image {
        height: 160px;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
      }

      .swiper-pagination-bullet {
        position: relative;
        width: 8px;
        height: 8px;
        border-radius: 8/2;
        background-color: $buttonTextColor;
        bottom: 12px;
      }
    }
        .express_delivery_badge_container {
          position: absolute;
          top: 11px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          border-radius: 3px;
          background: rgba(0, 0, 0, 0.30);
          height: 16px;
          padding: 4px;
          z-index: 999999;
          margin: 0 11px;
    
          .express_courier_badge_content {
            color: #F6F7F9;
            font-family: Open Sans;
            font-size: 6px;
            font-style: normal;
            font-weight: 700;
            line-height: 9px;
          }
        }
    .address_wrap {
      width: 100%;
      height: 52% !important;
      background-color: $primaryBackgroundColor;
      padding: 12px;
      border-radius: 20px;
      margin: 0 auto;
      position: absolute;
      top: 140px;
      z-index: 999;
      left: 0;
      right: 0;

      .request-id_quantity_wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .request-id_wrap {
          font-size: 16px;
          font-weight: 600;
          color: $requestIdColor;
        }

        .quantity_wrap {
          color: $contentLightColor;
          //font-family: Inter;
          font-style: normal;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: right;
        }
      }

      .address {
        font-size: 10px;
        font-weight: 400;
        color: $contentLightColor;
        padding-top: 6px;
        padding-bottom: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .payment_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;

      .lightning_icon {
        width: 15px;
        height: 15px;
        margin-bottom: 2px;
      }

      .pay_on_delivery {
        color: $contentLightColor;
        //font-family: Inter;
        font-style: normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: $requestIdColor;
        margin-left: 6px;
      }
    }

    .total_wrap {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      justify-content: space-between;
      align-items: center;

      .total_text_wrap {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;

        .total_text {
          font-size: 10px;
          font-weight: 400;
          color: $contentLightColor;
        }

        .value {
          font-size: 14px;
          font-weight: 500;
          color: $contentLightColor;
          margin-left: 5px;
        }
      }

      .reject_accept_buttons_wrap {
        display: flex;
        flex-direction: row;
      }

      .reject_button_wrap {
        height: 25px;
        background-color: $rejectButtonColor;
        border-radius: 40px;
      }
      .delivered_button {
        height: 34px;
        width: 86px;
        background: rgba(0, 215, 21, 0.09);
        // color: #00d715;
        border-radius: 40px;
        padding: 8px 16px;
        //font-family: 'Inter';
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
      }

      .accept_button_wrap {
        height: 25px;
        background-color: $primaryButtonColor;
        border-radius: 40px;
        margin-left: 10px;
      }
    }
  }
}
.no_history_found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Poppins';
  margin-top: 50%;
}
.Deliver{
  width: 66px !important;
  height: 31px !important;
  color: #ffff !important;
}
