@import '../../index.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.otp_verify_container {
  padding: 40px 20px;
  min-height: 100vh;

  .otp_verify_heading {
    margin-top: 45px;
    font-family: Open Sans;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0em;
    font-family: Open Sans;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
  }
  .otp_verify_content {
    margin-top: 12px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $textContentColor;
  }
  .otp_verify_input_field {
    margin-top: 40px;
    text-align: center;
  }
  .counter {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .otp_resend_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 3px;
    margin-top: 22px;
  }
  .otp_resend_seconds {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .resend {
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: $textContentColor;
  }
  .resend_button {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #fe5050;
  }
  .otp_submit_button {
    margin-top: 25px;
  }
}
.otp_verify_input_field_error input{
  margin-top: 30px;
  width: 64px !important;
  height: 58px !important;
  text-align: center !important;
  margin-right: 20px !important;
  font-size: 25px !important;
  border: 1px solid #e73f3f !important;
  outline: none !important;
  border-radius: 12px !important;
  transition: 0.1s ease-out !important;
  padding: 1rem 1.2rem !important;
  font-family: 'Open Sans', sans-serif !important;
  color: #0f182e !important;
  font-family: Open Sans;
  font-weight: 400 !important;
  line-height: 34px !important;
}
.otp_verify_input_field input {
  width: 64px !important;
  height: 58px !important;
  text-align: center !important;
  margin-right: 20px !important;
  font-size: 25px !important;
  border: 1px solid #e8e8e8 !important;
  outline: none !important;
  border-radius: 12px !important;
  transition: 0.1s ease-out !important;
  padding: 1rem 1.2rem !important;
  font-family: 'Open Sans', sans-serif !important;
  color: #0f182e !important;
  font-family: Open Sans;
  font-weight: 400 !important;
  line-height: 34px !important;
}
.otp_verify_error_message {
  color: #FE5050 !important;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #1d1d1d;
  margin-bottom: 12px;
  font-style: normal;
}
