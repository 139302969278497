@import '../../../index.scss';
.sampleworks_container {
  width: max-content;
  height: max-content;
  border-radius: 16px;
  box-shadow: 5px 10px 12px 8px #f3f4f4;
  .sampleworks_video_container {
    width: 200px;
    height: 200px;
    padding: 8px;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 16px;
    }
  }
  .sampleworks_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .sampleworks_footer_text1 {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
  }
  .sampleworks_footer_text2 {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: $textContentColor;
  }
}
