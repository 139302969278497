.location_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .sidebar_header {
    width: 20%;
  }
  .location_screen {
    width: 77%;
  }
  .location_wrapper {
    display: flex;
    background: #a6352f;
    align-items: center;
    gap: 20px;
    padding: 8px;
    width: 97%;
  }
  .customer_location {
    width: 24%;
  }
  .pleater_location {
    width: 22%;
    .css-b49die-control {
      padding: 1px !important;
    }
    .css-uy61kx-control {
      padding: 1px !important;
    }
  }

  .area_location {
    width: 22%;
    .css-b49die-control {
      padding: 1px !important;
    }
    .css-uy61kx-control {
      padding: 1px !important;
    }
  }
  .location_distance {
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    gap: 6px;
  }
  .distance_title {
    font-size: 13px;
    font-weight: 500;
  }
  .distance_value {
    font-size: 13px;
  }
  .clear_location {
    width: 8%;
    text-align: right;
    margin-right: 15px;
  }
  .clear_title {
    text-decoration: underline;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
  }
  .map_wrapper {
    min-height: 90vh;
    height: 100vh;
    width: 97%;
  }
  .dunzo_distance_heading {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
  }
  .dunzo_distance_content {
    font-family: Inter;
    font-size: 13px;
    font-weight:600;
    color: #ffffff;
  }
  .clear_location_left {
    margin-left: auto;
    padding-right:10px;
  }
  .info_window_location_details {
    width: 250px;
    color: #000000;
    font-weight: 800;
    font-family: 'Poppins';
    font-size: 16px;
    padding-bottom: 10px;
  }
  .infobox_flex_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px
  }
  .infobox_driver_name {
    font-size: 11px;
    color: #000000;
    font-weight:600;
  }
}
