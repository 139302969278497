@import '../../index.scss';
.success_container {
  height: 100vh;
  .success_wrapper {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .success_image {
      .delivery_man_image {
        height: 300px;
        width: 300px;
      }
    }
    .success_title {
      font-size: 24px;
      font-weight: 400;
      padding-top: 10px;
      margin-bottom: 15px;
    }
    .success_message {
      padding: 8px 20px;
      font-size: 16px;
      color: $primaryGrayColor;
      font-weight: 400;
      text-align: left;
    }
  }
  .success_footer {
    height: 10%;
    padding: 20px;
  }
}
