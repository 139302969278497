@import '../../../../index.scss';
.sidebar_containers {
  width: 100%;
  height: 100%;
  .sidebar_wrapper {
    min-height: 100vh;
    position: fixed;
    width: 20%;
    border-right: #efefef 1px solid;
background: #ffffff !important;

  }
  .sidebar_section {
    margin: 0 auto;
    padding-top: 40px;
    width: 80%;
  }
  .sidebar_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 187px;
    margin: 10px auto;
    height: 28px;
  }
  .sidebar_menu {
    margin-top: 65px;
  }
  .sidebar_heading {
    margin-top: 50px;
    height: 24px;
    width: 74px;
    border-radius: nullpx;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: $inputTextColor;
  }
  .sidebar_menu_list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
  }
  .sidebar_menu_list_active {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background: #a1342d;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
  }
  .sidebar_icon {
    border: 0px solid $inputPlaceholder;
    margin-left: 20px;
    height: 24px;
  }
  .sidebar_icon img {
    width: 24px;
    height: 24px;
  }

  .sidebar_list_name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #808191;
    //styleName: menu 14;
  }
  .sidebar_list_name_active {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }
  
  .line {
    height: 1px;
    background: #e4e4e4;
    width: 174px;
    margin: 20px auto 20px auto;
  }
  .sidebar_menu_logout{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
  }
  .sidebar_icon img{
    width: 24px;
    height: 24px;
  }
  .sidebar_menu_logout img:last-child {
    
    display: none;  
  }
  .sidebar_menu_logout:hover img:last-child {
    display: block;  
  }
  .sidebar_menu_logout:hover img:first-child {
    display: none;  
  }
  .sidebar_list_logout_name{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #808191;
  }
  .sidebar_menu_logout:hover .sidebar_list_logout_name{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  .sidebar_menu_logout:hover{
   
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background: #a1342d;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
     
   
  }
  
}
