@import '../../../index.scss';

.slider-horizontal {
    padding-bottom: 10px;
    // padding-top: 5px;
   
.rangeslider{
    display: block;
    box-shadow: none;
    .rangeslider__handle-tooltip{
        visibility: visible;
        width: 40px;
        height: 40px;
        text-align: center;
        position:absolute;
        background-color: $primaryButtonColor;
        transition: all 100ms ease-in;
        display: inline-block;
        color:white;
        left:50%;
        transform:translate3d(-50%,0,0)
    }
}
    .rangeslider__fill {
        background-color:$primaryButtonColor ;
        top: 0px;
        height: 100%;
        border-radius: 10px;
    }
    .rangeslider-horizontal {
        height: 3px;
        border-radius: 10px;
    }

    .rangeslider__handle {
        background-color: transparent;
        border-width: 0px;
        box-shadow: none;
        height: 20px;
        left: 0px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    }

    .rangeslider__handle::after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: $primaryButtonColor;
        border-width: 0px;
        border-color: transparent;
       
    }
    .rangeslider__handle-tooltip::after{
        border-left:8px solid transparent;
        border-right:8px solid transparent;
        border-top:8px solid $primaryButtonColor !important;
        left:50%;
        bottom:-8px;
        transform:translate3d(-50%, 0, 0);
    }
}