@import '../../../index.scss';
.confirmation_container {
  .confirmation_bottom_sheet {
    height: max-content;
  }
}
.confirmation_wrapper {
  .confirmation_header {
    width: 100%;
    padding:16px 20px 24px 20px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
  }
  .confirmation_body {
    width: 100%;
    padding: 0px 20px 12px 20px;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
  }
  .confirmation_footer_container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 40px 20px 20px 20px ;
    .confirmation_cancel_button {
      width: 40%;
      .cancel_button {
        background: $primaryBackgroundColor;
        color: $textContentColor !important;
      }
    }
    .confirmation_confirm_button {
      width: 40%;
    }
  }
  .confirmation_button{
    text-align: center;
    width: 90%;
  }
  .confirmation_button_success{
    padding: 10px 15px !important;
  }
  .confirmation_text_container {
    padding: 20px;
    .confirmation_textarea {
      outline: none !important;
      background: $primaryBackgroundColor;
      height: 120px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .booking_confirmation_body{
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    text-align: left;
  
  }
  .booking_confirmation_body_wrapper{
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap:5px;
    padding:0px 20px 12px 20px;
    .booking_confirmation_body_number{
      font-size: 15px;
      font-weight: 400;
    }
  }
 
}
