@import '../../index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.order_screen_header_wrapper{
  // height: 10vh;
}
.order_screen_wrapper{
  height: 87vh;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index:1;
  padding-bottom: 200px;
}
.pleat_heading {
  margin-top: 25px;
  color: $primaryButtonColor;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 2px;
}
.details_main_wrapper{
  overflow-y: scroll;
  height: 80vh;
}

.pleater_buttons_wrap {
  display: flex;
  flex-direction: row;
  padding: 6px 24px;

  .request_button {
    height: 35px;
    background-color: $primaryButtonColor;
    border-radius: 40px;
  }

  .order_button {
    height: 35px;
    background-color: $orderButtonColor;
    border-radius: 40px;
    margin-left: 20px;
  }
}
.details_footer_container{
height: 10vh !important;

 
}

.orders_card_wrap {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left:10px;
}