@import '../../index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.notify_container {
  // background-color: $notifyBackground;
  height: 800px;
  overflow: auto;
  height: calc(100vh - 70px);
 

  // width:100%;
  // height:100%;
}

.heading_container {
  // height: 60px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 

  .nav_wrap {
    margin: 25px;
    height: 50px;
    width: 50px;
    border-radius: 16px;
    background-color: $iconBackground;
    border-color: $iconBorderColor;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow_image {
      height: 20px;
      width: 20px;
    }
  }

  .heading {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    
  }
}

.notify_component {
  margin-top: 15px;
}
.notification_wrapper{
  height: 85vh;
  overflow-y: scroll;
}