@import '../../index.scss';
.payment_container {
  min-height: 100vh;
  .payment_header_wrapper {
    height: 10vh;
  }
  .payment_header_container {
    // border-bottom: 2px solid $borderColor;
    padding: 0 5px;
  }
  .payment_wrapper_scroll {
    overflow-y: scroll;
    height: 80vh;
  }
  .payment_balance_amount_container {
    margin: 0px 15px 0px 15px;
    // padding-top: 20px;
    padding-bottom: 10px;
    // border-bottom: 2px solid $borderColor;
    .payment_balance_amount_wrapper {
      width: 100%;
      position: relative;
      .payment_balance_card {
        height: 130px;
        .payment_card {
          width: 100%;
          height: 100%;
        }
      }
    }
    .payment_balance_card_container {
      width: 100%;
      position: absolute;
      top: 8px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      align-items: center;
      .payment_balance_card_wrapper {
        .payment_balance_your_money {
          font-size: 14px;
          font-weight: 400;
          color: $primaryGrayColor;
        }
        .payment_balance_money_value {
          font-size: 24px;
          font-weight: 600;
          color: $buttonTextColor;
          padding: 5px 0px;
        }
        .payment_balance_earning {
          display: flex;
          gap: 5px;
          font-size: 12px;
          .payment_balance_earning_text {
            font-weight: 400;
            color: $primaryGrayColor;
          }
          .payment_balance_earning_value {
            font-weight: 600;
            color: $buttonTextColor;
          }
        }
      }
      .payment_balance_card_button {
        .transfer_button {
          color: $primaryButtonColor !important;
          background: $buttonTextColor;
          border-radius: 40px;
        }
      }
    }
  }
  .payment_subheading {
    padding: 15px 0px;
    font-weight: 500;
    font-size: 18px;
  }
  .payment_overall_transaction_container {
    padding: 0px 15px;
    .payment_overall_transaction_header {
      display: flex;
      justify-content: space-between;
    }
    .payment_earning_card_container {
      display: flex;
      justify-content: space-between;
      padding: 0px 0px;
      width: 100%;
      .payment_earning_wrapper1 {
        border: 1px solid $primaryGreenBorder;
        padding: 10px;
        border-radius: 10px;
        width: 48%;
        background: $primaryGreenBackground;
      }
      .payment_earning_wrapper1_inactive {
        // border: 1px solid $primaryGreenBorder;
        padding: 10px;
        border-radius: 10px;
        width: 48%;
        background: $primaryGreenBackground;
      }
      .payment_earning_wrapper2 {
        border: 1px solid $primaryYellowBorder;
        background: $primaryYellowBackground;
        padding: 10px;
        border-radius: 10px;
        width: 48%;
      }
      .payment_earning_wrapper2_inactive {
        // border: 1px solid $primaryYellowBorder;
        background: $primaryYellowBackground;
        padding: 10px;
        border-radius: 10px;
        width: 48%;
      }
      .payment_total_earning_text {
        font-size: 10px;
        font-weight: 400;
        color: $primaryGrayColor;
      }
      .payment_total_earning_value {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .payment_transaction_container {
    padding: 0px 20px;
    overflow: auto;
    .payment_transaction_wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
      padding: 10px 0px;
      .payment_transaction_bullet_icon {
        width: 10%;
      }
      .payment_transaction_status {
        width: 60%;
        .payment_transaction_status_text {
          color: $primaryYellowBorder;
          font-size: 16px;
          font-weight: 600;
        }
        .payment_transaction_status_success{
          color: $primaryGreenBorder;
          font-size: 16px;
          font-weight: 600;
        }
        .payment_transaction_status_time {
          font-size: 12px;
          font-weight: 300;
          color: $primaryGrayColor;
        }
      }
      .payment_transaction_amount {
        width: 30%;
        font-size: 18px;
        font-weight: 600;
        text-align: right;
      }
    }
  }
  .payment_footer_container {
    width: 100%;
    position: fixed;
    height: 10vh;
    bottom: 0px;
  }
  .payment_overall_processing_amount_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
  
    .payment_overall_processing_status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      font-size: 16px;
    }
  
    .payment_overall_processing_amount_list {
      font-weight: 500;
    }
  }


}
// .css-1s2u09g-control{
//     display: flex;
//     align-items: flex-end;
//     height: 11px;
//     // width: 35px;
//     font-size: 10px;
//     background-color: $buttonTextColor !important;
//     border: 1px solid $borderColor;
//   }
