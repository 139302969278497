@import '../../../index.scss';

.dashboard_container {
  width: 100%;
  height: 100%;
  display: flex;
  .dashboard_wrapper {
    width: 80%;
    background: #f6f6f6;
    height: 100vh;
    overflow-y: scroll;
  }

  .sidebar-header {
    width: 20%;
  }
  .admin_header {
    height: 11%;
    margin: 0px auto 7px auto;
  }
  .dashboard_counter_barchart {
    margin: 10px auto;
    height: 90%;
    width: 96%;
  }
  .counter_section {
    // width: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    background: #fff;
  }
  .count_value_wrapper {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .card_wrapper_active {
    width: 300px;
    border-right: #efefef 1px solid;
    padding: 20px 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.card_border {
      border-right: 0 !important;
    }
    .card_header {
      //styleName: headline/h6 - 18;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0px;
      text-align: center;
      color: #808191;
    }
    .card_counter {
      //styleName: headline/h3 - 40;
      font-family: Poppins;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      margin-left: 5px;
    }
  }
  .card_wrapper {
    width: 250px;
    padding: 40px 60px 40px 60px;
    background: #fff;
  }

  .barchart_wrapper {
    margin: 10px 0;
    gap: 10px;
    display: flex;
    width: 100%;
  }
  .barchart_panel {
    width: 50%;
    background: #ffffff;
    border-radius: 5px;
    min-height: 250px;
    max-height: 270px;
  }
  .barchart_subheading {
    margin: 8px 0 0 8px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
  }
  .bar_chart {
    width: 100%;
    margin: 10px 0;
  }
  .bar_chart canvas {
    width: 100% !important;
    padding: 10px;
    min-height: 225px !important;
    max-height: 240px !important;
  }
  .empty_text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 185px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #808191;
  }
  .date_picker_wrapper {
    .custom-calendar.rmdp-wrapper,
    .rmdp-container .custom-calendar.ep-arrow::after {
      background-color: #ffffff;
    }

    .rmdp-container .rmdp-selected .sd {
      background-color: #a1342d;
    }
    .rmdp-container .rmdp-day:hover .sd {
      background-color: #a1342d;
      opacity: 0.2;
      color: #ffffff;
    }
    .rmdp-container .rmdp-today .sd {
      border: #a1342d 1px solid;
      background: #ffffff;
      color: #11142d;
    }
    .rmdp-container .rmdp-week .rmdp-week-day {
      color: #a1342d;
    }
    .rmdp-container .rmdp-arrow-container .rmdp-arrow {
      border: solid rgb(20, 19, 19);
      border-width: 0 2px 2px 0;
    }
    .rmdp-container .rmdp-arrow-container:hover {
      background: #a1342d;
      box-shadow: 0 0 3px #8798ad;
    }
    .rmdp-container .rmdp-arrow-container:hover .rmdp-arrow {
      border: solid rgb(255, 254, 254);
      border-width: 0 2px 2px 0;
    }
  }
}
