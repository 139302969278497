@import '../../index.scss';
.order_card_status{
  display: flex;
  flex-direction: column;
  width: 270px !important;
  margin: 0 auto;
  height: 250px;
  box-shadow: 0px 0px 12px -4px rgba(0,0,0,0.3);
  border-radius:21px;
.order_card_status_container {
  width: 100% !important;
  margin: 0 auto;
  position: relative;
.express_delivery_badge_container {
  position: absolute;
  top: 11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.30);
  height: 16px;
  padding: 4px;
  z-index: 999999;
  margin: 0 11px;
  .express_courier_badge_content {
  color: #F6F7F9;
  font-family: Open Sans;
  font-size: 6px;
  font-style: normal;
  font-weight: 700;
  line-height: 9px;
  }
}
  .swiper_wrap {
    position: relative;
    .swiper-container {
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }
    .saree_image {
      width: 288px;
      height: 162px;
      object-fit: cover;
    }
    .saree_image_full {
      width: 100%;
      height: 220px;
      object-fit: cover;
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 8px;
      height: 8px;
      border-radius: 8/2;
      background-color: white;
      bottom: 12px;
      // display: none;
    }
  }

  .address_wrap {
    width: 100% !important;
    background-color: $primaryBackgroundColor;
    padding: 12px;
    border-radius: 20px;
    margin: 0 auto;
    position: absolute;
    top: 150px;
    z-index: 999;
    left: 0;
    right: 0;
    padding-bottom: 10px;
    height: 100px;

    .request-id_quantity_wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .request-id_wrap {
        font-size: 16px;
        font-weight: 600;
        color: $requestIdColor;
      }

      .quantity_wrap {
        font-size: 10px;
        font-weight: 400;
        color: $contentLightColor;
      }
    }
    .order_status_wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 15px;
      .order_status_text{
        font-weight:500;
      }
      .order_status_status{
        font-weight:500;
        color: green;
      }
      .order_status_failed{
        font-weight: 500;
        color: red;
      }
    }
  }
  .stepper_wrap {
    padding: 20px;
  }
}
}
