@import '../../index.scss';
.account_container {
   min-height: 100vh;
  .account_header_container {
    padding: 10px;
  }
  .account_list_container {
    padding: 15px 25px;
    .account_list_flex_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
    }
    .react-switch-bg{
      margin: 0px !important;
    }
    .react-switch-handle{
      width: 26px !important;
      height: 26px !important;
    }
    
    .account_avaliability_flex_wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 15px;
      align-items: center;
    }
    .account_list_name {
      font-size: 18px;
      font-weight: 400;
      margin-top: -4px;
    }

    .account_list_element {
      height: 100%;
      display: flex;
      cursor: pointer;
      gap: 15px;
      padding-bottom: 20px;
      align-items: center;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      .symbol_wrap_edit{
        width: 30px;
        height: 30px;
      }
      .account_list_icon_edit{
        margin-left: 0;
      }
      .account_list_element_name {
        font-size: 18px;
        font-weight: 400;
        margin-top: -5px;
      }
      .account_list_element_name_edit {
        font-size: 18px;
        font-weight: 400;
        margin-top: -5px;
        // margin-left: -5px;
      }
    }
  }
  .account_footer_container {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  .build_number_container{
    width: 100%;
    justify-content: center;
    align-items: center;
    // background-color: blue;
    position: absolute;
    bottom: 86px;
    left:0px;
    .build_number{
      font-size: 8px;
      text-align: center;
      color: grey;
    }
  }
  .address_screen_confirm_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}

.account_screen_feedback_wrapper {
  padding: 10px 20px 20px 20px;
}

.account_screen_feedback_input_wrapper {
   padding: 10px;

}

