@import '../../../index.scss';

.sidebar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-right: 0.5px solid $secondaryBtnColor;
  height: 100vh;
  justify-content: space-between;
  transition: width 0.3s ease;

  .sidebar_header {
    width: 100%;
    display: flex;
    padding-left: 12px;
    min-height: 55px;
    // align-content: center;
    // justify-content: center;
    // justify-content: space-between;

    .sidebar_logo {
      padding-left: 40px;
    }
  }
  .sidebar_heading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    padding: 12px;
    padding-top: 40px;
  }
  .sidebar_element_container {
    transition: all 0.3s ease;

    .sidebar_element {
      display: flex;
      padding: 15px;
      border-radius: 16px;
      margin: 10px 0;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background: $secondaryBtnColor;
        cursor: pointer;
      }
      .sidebar_icon {
        margin-right: 20px;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sidebar_element_name {
        //font-family: 'Inter';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // &:hover {
        //   color: $buttonTextColor !important;
        // }
      }
    }
  }
}
.active_tab {
  background: $gradientButtonColor;
  &:hover {
    background: $gradientButtonColor !important;
    color: $inputHeadingColor !important;
  }
}
.element_name_active {
  color: $buttonTextColor !important;
}
a {
  text-decoration: none;
  color: $primaryBtnColor !important;
}
.sidebar_active_route {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sidebar_back_icon {
  margin-right: 10px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
}
.sidebar_logout_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar_content {
  height: 90%;
}
.sidebar_user_image {
  // height: 100px;
  object-fit: cover;
}
.sidebar_logout_user_name {
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $primaryBtnColor;
  padding-bottom: 5px;
}
.sidebar_logout_user_account {
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: $inputHeadingColor;
}
.sidebar_logout_icon {
  &:hover {
    cursor: pointer;
  }
}
.sidebar_profile_image {
  border-radius: 100%;
  object-fit: cover;
}
.sidebar_hide_container {
  // width: 40%;
  transition: width 0.3s ease;
}

.sidebar_hamburger_icon {
  &:hover {
    cursor: pointer;
  }
}
.sidebar_hide_icon {
  margin-right: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
