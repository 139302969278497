@import '../../../index.scss';
.footer_container {
  z-index: 999;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px -2px 3px 0px #dbdbdb3b;
  background: $buttonTextColor;
  position: fixed;
  bottom: 0;
  .book_button {
    border-radius: 24px;
  }
  .footer_home_icon{
    cursor: pointer;
  }
  .footer_orders_icon{
    cursor: pointer;
  }
  .footer_notification_icon{
    cursor: pointer;
  }
  .footer_list_icon{
    cursor: pointer;
  }
}
