@import '../../index.scss';

.address_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.address_screen_nav_button {
  position: absolute;
  z-index: 9999;
  top: 40px;
  left: 0;
  right: 0;
  color: black;
  margin: 10px;
}
.address_screen_nav_button_hide {
  display: none;
}
.address_screen_current_location_hide {
  display: none;
}

.address_screen_nav_button_color {
  background-color: #ffff;
  color: black;
  opacity: 1;
  border: none;
}

.address_screen_current_location {
  position: absolute;
  z-index: 9999;
  bottom: 280px;
  color: black;
  width: 220px;
}
.address_screen_nav_button_color_locations {
  padding: 10px 10px 6px 10px;
  display: flex;
  opacity: 1;
  border-radius: 10px;
  justify-content: space-evenly;
  border: 1px solid $primaryButtonColor;
  background-color: white;
  color: $primaryButtonColor;
  box-shadow: 0px 1px 5px 0px $primaryButtonColor;
  cursor: pointer;

  .location_img {
    padding-right: 10px;
    padding-top: 2px;
  }
}

.address_wrapper {
  padding: 10px 20px 20px 20px;
  .address_save_address_text {
    font-size: 14px;
    font-weight: 400;
    color: $primaryGrayColor;
  }
}
.address_house_number,
.address_floor_number,
.address_building_name {
  padding: 15px 0px;
}

.address_radio_box_container {
  padding: 15px 0px;
  display: flex;
  gap: 20px;
  .address_radio_box_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.address_checkbox_container {
  padding: 15px 0px 25px 0px;
  display: flex;
  gap: 8px;
}
.address_main_wrapper {
  height: 100%;
}
.address_map {
  position: relative;
}
.address_form_button {
  position: absolute;
  // top: 480px;
  min-height: 10%;
  background-color: white;
  padding: 12px 12px;
  padding-bottom: 26px;
  bottom: 0;
}
.latlong_address {
  margin: 20px 0;
  padding: 0 10px;
}
.latlong_address_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #0f182e;
}
.address_select_address_container {
  padding: 0px 20px 30px 20px;
  .select_saved_address_container {
    width: 100%;
    background: $primaryBackgroundColor;
    border-radius: 14px;
    margin: 10px 0px;
    padding-bottom: 15px;
    .select_saved_address_subheading {
      font-size: 18px;
      padding: 18px 0px 0px 15px;
      font-weight: 600;
    }
    .select_address_container {
      display: flex;
      gap: 10px;
      padding: 12px 15px 7px 15px;
      position: relative;
      // border-bottom: 1px solid $borderColor;
      justify-content: flex-start;
      .select_address_radiobox {
        padding-top: 4px;
        width: 10%;
      }
      .select_address_wrapper {
        width: 75%;
        .select_address_type {
          font-weight: 600;
          font-size: 16px;
        }
        .select_address_text {
          font-weight: 300;
          word-wrap: break-word;
        }
      }

      .select_address_more_option {
        width: 10%;
        display: flex;
        justify-content: flex-end;
        .more_icon {
          height: 13px;
          width: 3px;
          margin-top: 4px;
        }
      }
      .select_address_menu {
        position: absolute;
        top: 42px;
        right: 8px;
        background-color: $buttonTextColor;
        padding: 15px;
        border-radius: 14px;
        font-weight: 300;
      }
    }
  }
  .add_address_button {
    margin: 20px 0px;
    background: $secondaryButtonColor;
    color: $addButtonTextColor !important;
  }
}
.address_radiobox_container {
  display: flex;
  gap: 20px;
  padding: 10px 0px 20px 0px;
  .address_radiobox_wrapper {
    display: flex;
    gap: 5px;
    .address_radiobox_text {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.address_screen_edit_and_delete_wrapper {
  display: flex !important;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
  margin: 0 15px;
  // pading-left
  padding: 10px 0px 10px 40px;
}
.address_screen_edit_button {
  color: #fe5050;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.address_screen_container {
  border-bottom: 1px solid $borderColor;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.address_screen_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address_screen_confirm_text {
  width: 100%;
  padding: 0px 20px 12px 20px;
  font-weight: 400;
  font-size: 15px;
}

.address_confirm_button_wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
}

.address_screen_confirm_button {
  margin: 0 0px !important;
  padding: 12px 35px !important;
}
.address_screen_change_button {
  background-color: #f4f6f8;
  color: #768089 !important;
  margin: 0 0px !important;
  padding: 12px 35px !important;
}
.address_screen_bottom_line {
  margin: 5px 0;
  border: 1px solid #f4f6f8;
}
.address_screen_confirm_wrapper {
  padding: 10px;
}
.address_screen_confirm_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
.address_sreen_confirmation_header {
  width: 100%;
  padding: 16px 20px 24px 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
}
// .google_map_auto_complete{
.google_map_auto_complete {
  position: absolute;
  display: flex;
  z-index: 9999;
  // width: 100%;
  justify-content: center;
  align-items: center;
  top: 51px;
  // right: 8px !important;
  // color: black;

  .autocomplete_placeholder::placeholder {
    font-size: 14px;
    color: #9c312b;
  }
}

.pac-container {
  margin-top: 5px;
  z-index: 9999;
}
