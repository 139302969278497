.gm-style-mtc{
    display: none !important;
}
.gm-fullscreen-control{
    display: none !important;
}
.gm-svpc{
    display: none !important;
}

.gmnoprint{
    display: none !important;
}
