@import '../../../index.scss';
.payment_wrapper {
  padding: 0px 20px;
  .payment_body_container {
    .payment_amount_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 10px;
      .payment_value {
        font-size: 40px;
        font-weight: 600;
      width: 65%;
      margin: 0 auto;
        // border-bottom: 1px solid $borderColor;
      }
      .payment_value input {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 1px solid black;
        text-align: center;
        // font-size: 25px;
      }
      .payment_value_input_text {
        font-family: Lato;
        font-size: 40px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
        width: 100%;
        
      }
      .payment_amount_wrapper {
        background: $background;
        display: flex;
        padding: 8px;
        border-radius: 10px;
        margin-top: 10px;
        gap: 8px;
        font-size: 13px;
        font-weight: 300;
        .payment_available_balance_value {
          color: #00a510;
          font-weight: 600;
        }
      }
    }
    .payment_instruction_text {
      text-align: center;
      padding: 20px 20px 10px 20px;
      font-size: 12px;
      font-weight: 600;
    }
    .payment_instruction_not_sufficent_text{
      text-align: center;
      padding: 20px 20px 10px 20px;
      font-size: 12px;
      font-weight: 600;
      color: #FE5050;
    }
  }
  .payment_footer_container {
    padding-bottom: 20px;
  }
}
.payment_bottomsheet {
  height: max-content;
}
