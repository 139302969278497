@import '../../index.scss';

.notification_wrap {
  margin-top: 10px;

  .notification_container {
    // margin-left: 25px;
    margin-right: 25px;
    height: 80px;
    background-color: $buttonTextColor;
    border-radius: 12px;
    display: flex;
    flex-direction: row;

    .saree_image_container {
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 20%;
      height: 80px;

      .saree_image {
        height: 50px;
        width: 50px;
        border-radius: 12px;
      }
    }

    .status_wrap {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 10px;
      height: 80px;

      .status {
        font-size: 14px;
        font-weight: 600;
        color: $statusTextColor;
        font-family: Open Sans;
        line-height: 22px;
      }

      .order_id {
        font-size: 11px;
        color: $textContentColor;
        margin-top: 2px;
        font-family: Open Sans;
        font-weight: 400;
        line-height: 16px;
      }

      .view_text {
        font-size: 10px;
        font-weight: 500;
        color: $dashedBorderColor;
        margin-top: 2px;
      }

      .review_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .edit_image {
        width: 8px;
        height: 8px;
        margin-right: 2px;
        margin-top: 4px;
      }
    }

    .time_wrap {
      width: 30%;
      height: 80px;
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
      margin-top: 15px;

      .time {
        font-size: 8px;
        font-weight: 400;
        color: $timeTextColor;
      }
    }
  }
}
