@import '../../index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.get_started_container {
  padding: 15px;
  .get_started_skip {
    display: flex;
    padding: 0px 5px;
    justify-content: flex-end;
    align-items: center;
    //font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $textContentColor;
  }
  .get_started_landing_img {
    text-align: center;
    margin-top: 70px;
  }
  .get_started_content_heading {
    margin-top: 30px;
    //font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: $contentDarkColor;
  }
  .get_started_content {
    margin-top: 2px;
    padding: 0 25px;
    //font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: $contentLightColor;
  }
  .get_started_box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .box1 {
    width: 20px;
    height: 10px;
    background-color: $primaryButtonColor;
    border-radius: 5px;
  }
  .box {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #e0e0e0;
  }
  .get_started_button {
    position: absolute;
    bottom: 0px;
    height: 60px;
    text-align: center;
    width: 92%;
  }
}
