@import '../../../index.scss';
.addsaree_popup_bottomsheet {
  padding: 10px;
  .addsaree_popup_wrapper {
    padding: 10px 0px;

    .addsaree_popup_count {
      font-weight: 400;
      font-size: 14px;
      padding: 10px;
    }
    .addsaree_popup_button {
      width: 100%;
      display: flex;
      justify-content: center;
      .add_saree_button {
        width: 40%;
        background: $secondaryButtonColor;
        color: $viewTextColor;
      }
    }
  }
  .addsaree_popup_footer {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    .addsaree_popup_cancel_button {
      width: 45%;
      .cancel_button {
        background: $primaryBackgroundColor;
        color: $textContentColor;
      }
    }
    .addsaree_popup_deliver_button {
      width: 45%;
    }
  }
}
.popup_bottomsheet {
  height: max-content;
}
.addsaree_popup_images_container {
  display: flex;
  padding: 0px 10px;
  gap: 10px;
  .camera_icon {
    height: 63px;
    width: 63px;
  }
  .saree_images {
    height: 63px;
    width: 63px;
    border-radius: 50%;
    object-fit: cover;
  }
  .addsaree_popup_camera_gallery {
    display: flex;
    overflow: auto;
    gap: 10px;
  }
  .addsaree_popup_images_wrapper {
    position: relative;
    .close_icon {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}
