@import '../../index.scss';
.cancel_container {
  height: 100vh;
  .cancel_wrapper {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .cancel_image {
      .delivery_man_image {
        height: 300px;
        width: 300px;
      }
    }
    .cancel_title {
      font-size: 24px;
      font-weight: 400;
      padding-top: 10px;
    }
    .cancel_message {
      text-align: center;
      padding: 10px 20px;
      font-size: 16px;
      color: $primaryGrayColor;
      font-weight: 400;
    }
  }
  .cancel_footer {
    height: 10%;
    padding: 20px;
  }
}
