@import '../../index.scss';
.reviews_container {
  height: 100vh;
  .reviews_footer {
    height: 10%;
    padding: 20px;
  }
}
.reviews_bootomsheet_container {
  padding: 10px;
  .reviews_bottomsheet_rating_container {
    margin: 10px;
    background: $primaryBackgroundColor;
    text-align: center;
    padding: 20px;
    border-radius: 14px;
    .reviews_rating_text {
      padding-bottom: 10px;
      font-size: 13px;
      font-weight: 400;
      color: $primaryGrayColor;
    }
  }
  .reviews_bottomsheet_textarea {
    padding: 10px;
    .textarea {
      height: 72px;
      border-radius: 14px;
      // border: 1px solid $borderColor;
    }
    // .onFocused{
    //   height: 72px;
    //   border-radius: 14px;
    //   border: 1px solid red;
    // }
  }
  .reviews_bottomsheet_button {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    .add_reviews_button {
      width: 145px;
    }
  }
}
.reviews_successful {
  height: 100vh;
  .reviews_wrapper {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .reviews_image {
      .delivery_man_image {
        // height: 250px;
        width: 300px;
        object-fit: contain;
      }
    }
    .reviews_title {
      font-size: 24px;
      font-weight: 400;
      padding-top: 10px;
    }
    .reviews_message {
      text-align: center;
      padding: 10px 20px;
      font-weight: 400;
      font-size: 16px;
      color: $primaryGrayColor;
    }
  }
}
