@import '../../../index.scss';

.template_btn_wrapper {
  width: 100%;
  height: 50px;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid $secondaryBtnColor;
  padding: 13px;
  //font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
}
.selected_template {
  background-color: $socialBtnColor;
  border: 2px solid $socialBtnColor;
  color: $buttonTextColor;
}
