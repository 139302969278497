.terms_and_condition_screen_container {
  min-height: 100vh;
  .terms_and_condition_screen_header {
    height: 12vh;
  }
  .terms_and_condition_screen_content_wrapper {
    height: 78vh;
    overflow-y: scroll;
    padding:0 30px;
  }
  .terms_and_conditon_screen_footer_container {
    height: 10vh;
  }
}
