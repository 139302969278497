@import '../../index.scss';
.transfer_container {
  height: 100vh;
  .transfer_wrapper {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .transfer_image {
      .success_tick {
        height: 130px;
        width: 130px;
      }
    }
    .transfer_title {
      font-size: 24px;
      font-weight: 400;
      padding-top: 10px;
    }
    .transfer_message {
      text-align: center;
      padding: 10px 20px;
      font-size: 16px;
      color: $primaryGrayColor;
      font-weight: 400;
    }
  }
  .transfer_footer {
    height: 10%;
    padding: 20px;
  }
}
