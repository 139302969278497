@import '../../../index.scss';
.navbar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  align-items: center;
  padding-right: 50px;
  &:hover {
    cursor: pointer;
  }
  .search {
    width: 500px;
  }

  .create_button {
    // width: 180px;
    .create_btn {
      background: $gradientButtonColor;
    }
  }
  .margin_right {
    margin-right: 20px;
  }
}
.navbar_logout_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navbar_content {
  height: 90%;
}
.navbar_user_image {
  // height: 100px;
  object-fit: cover;
}
.navbar_logout_user_name {
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $primaryBtnColor;
  padding: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar_logout_user_account {
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: $inputHeadingColor;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar_logout_icon {
  &:hover {
    cursor: pointer;
  }
}
.navbar_profile_image {
  border-radius: 100%;
  object-fit: cover;
}
.navabr_profile_hover_container {
  background-color: $buttonTextColor;
  // width: 100%;
  position: absolute;
  top: 45px;
  left: -20px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px 20px;
  transition: all 0.3s ease;
}
.navbar_profile_user_name {
  //font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: $primaryBtnColor;
  padding: 3px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
