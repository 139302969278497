.admin_coupon_screen_container {
  width: 100%;
  height: 100%;
  display: flex;

  .sidebar-header {
    width: 20%;
  }
  .admin_coupon_heading_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
    .admin_coupon_heading_content {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      color: #11142d;
    }
    .admin_coupon_create_button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a1342d;
      color: #ffffff;
      width: 150px;
      height: 46px;
      border-radius: 16px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      cursor: pointer;
    }
  }
  .admin_coupon_wrapper {
    width: 80%;
    background: #f6f6f6;
    height: 100vh;
  }
  .admin_coupon_table_wrapper {
    width: 95%;
    height: 78%;
    margin: 0 15px 15px 15px;
    background: #ffff;
    border-radius: 12px;
    padding: 20px 30px;
 
  }
  .admin_coupon_table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 10px;
    height: 9%;
    width: 25%;
    margin-bottom: 20px;
    .admin_coupon_search_wrapper {
      width: 100% !important;
    }
  }
}
