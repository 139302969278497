@import '../../../index.scss';

.ql-toolbar.ql-snow {
  border: none;
  background-color: $inputBackground !important;
  border-bottom: 1px solid $inputBackground !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.ql-container.ql-snow {
  height: 200px;
  border: none;
}
.ql-editor {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  background-color: $inputBackground !important;
}
.quill {
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}
.ql-toolbar.ql-snow {
display: flex;
justify-content: flex-end;
}