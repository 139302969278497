@import '../../index.scss';
.select_coupon_container {
  // padding: 10px;
  height: 100%;
  .coupon_header {
    .header_text {
      font-family: Open Sans;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      color: #0F182E;
    }
  }
  .coupon_input_wrapper  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #E8E8E8;
    border-radius: 14px;
    padding: 0px 12px;
    input{
      background-color: transparent !important;
      width: 100%;
      height: 54px;
      border: none;
      outline: none ;
      color: #1D1D1D;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
     }
     input::placeholder {
     color: #1D1D1D;
     font-family: Inter;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: 150%; /* 24px */
     }
     .coupon_input_button_text {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: #9C312B;
     }
  }
  .coupon_input_container {
    padding: 20px 10px;
    .coupon_invalid_text {
      color: #FF3D00;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
      margin-top: 6px;
    }
  }
  .coupon_available_container {
    padding: 10px;
    overflow-y: scroll;
    height: 80vh;
    padding-bottom: 10vh;

    .coupon_available_heading {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      color: #0F182E;
      
    }
    .coupon_available_wrapper {
      padding: 15px 0px;
      border-bottom: 1px solid $borderColor;
      .coupon_unlock_content {
        font-family: Inter;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #ff3d00;
        margin-top: 12px;
      }
      .coupon_screen_button_disable {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: right;
        color: #d2d2d2;
        padding: 2px 18px;
      }
      .coupon_type {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        color: #333333;
        
      }
      .coupon_value {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #C4C4C4;
        margin-top: 4px;
        margin-bottom: 12px;
      }
      .coupon_footer {
        display: flex;
        justify-content: space-between;
        .coupon_code {
          border: 1px dashed $viewTextColor;
          padding: 3px 5px;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
        }
        .coupon_apply_button {
          font-size: 12px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 1.6px solid;
          padding: 2px 18px;
          border-radius: 16px;
          color: $primaryButtonColor;
        }
      }
    }
  }
  
  .react-responsive-modal-modal{
    width: 100% !important;
    margin: 0;
  }
  @media screen and (min-width:500px) {
    .react-responsive-modal-modal{
      width: 350px !important;
      margin: 0;
    }
    
  }
}
