@import url('../../../index.scss');

.delete_pop_modal {
  width: 350px !important;
  border-radius: 16px;
}
.delete {
  background-color: white;
}
.delete_pop_btn_cancel {
  background: #e4e4e4;
  color: black;
}
