.courier_card_container {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #e8e8e8;

  .courier_card_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .courier_card_name_secondary_color {
      background-color: #3db2ff !important;
    }
    .courier_card_name_wrapper {
      height: 32px;
      padding: 0 10px;
      border-radius: 6px;
      background: #a1342d;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .courier_card_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }

      .courier_card_name {
        color: #fff;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
      }
    }

    .courier_checkbox_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .courier_card_delivery_timing_content {
    margin-top: 16px;
    color: #0f182e;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .courier_card_delivery_fee_content {
    color: #0f182e;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 6px;
  }
}
.courier_card_container_space {
  margin-top: 16px;
}
