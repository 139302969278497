@import '../../../index.scss';

.multi-range-slider {
  box-shadow: none;
  border: none;
}
.multi-range-slider .bar-inner {
  background-color: $primaryLinkColor;
  box-shadow: none;
  border: none;
}
.multi-range-slider .thumb::before {
  content: '';
  background-color: $primaryLinkColor;
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  z-index: 1;
  margin: -8px;
  cursor: pointer;
}
.multi-range-slider .bar-left {
  box-shadow: none;
}

.multi-range-slider .bar-right {
  box-shadow: none;
}
.multi-range-slider .thumb * {
  background-color: $primaryLinkColor;
  box-shadow: none;
  border: none;
}
