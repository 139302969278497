@import '../../../index.scss';
.header_container {
  display: flex;
  padding: 10px;
  .header_back_button {
    width: 10%;
  }
  .header_text {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
  }
}
