@import '../../../index.scss';
.invite_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .invite_wrapper {
    padding: 20px;
    width: 100%;
    border-radius: 24px;
    .invite_header {
      display: flex;
      justify-content: space-between;
      .invite_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
    }
    .invite_subheading {
      //font-family: 'Inter';
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 10px;
    }
    .invite_send_button {
      display: flex;
      justify-content: space-around;
      padding-top: 30px;
    }
    .send_invite_button {
      margin: 0 0px !important;
      padding: 12px 40px !important;
    }
  }
}
.invite_wrapper .react-responsive-modal-modal {
  border-radius: 16px;
  // width: 350px !important;
}
.react-responsive-modal-modal {
  width: 100% !important;
  margin: 0;
}
@media screen and (min-width: 500px) {
  .react-responsive-modal-modal {
    width: 350px !important;
    margin: 0;
  }
}
