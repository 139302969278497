@import '../../../index.scss';

.button_wrapper {
  // height: 56px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: white !important;
  background: $primaryButtonColor;
  width: 100%;
  padding: 15px 20px;
  // transition: 0.2s;
}
.primary_button_img {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_blue{
  color: #3DB2FF !important;
}
.button_red{
  color: #FE5050 !important;

}
.button_text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // color: #ffff;
  text-align: center;
letter-spacing: 0.04em
}
.button_wrapper:hover {
  // background: $activeTabColor !important;
  // color: $buttonTextColor !important;
  cursor: pointer;
  // transition: all 0.2s linear;
}
.disabled {
  background: $inputBackground !important;
  color: $inputTextColor !important;
  height: 56px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: white;
  background: $primaryBtnColor;
  width: 100%;
}
.primary_button_right_img {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary_btn_icon {
  width: 20px;
  height: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1520px) {
  .button_text {
    font-size: 12px;
  }
  .button_wrapper {
    padding: 13px;
  }
}
