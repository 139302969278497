@import '../../../index.scss';
.users_container {
  width: 100%;
  height: 100%;
  display: flex;

  .sidebar-header {
    width: 20%;
  }
  .users_wrapper {
    width: 80%;
    background: #f6f6f6;
    height: 100vh;
  }
  .user_table_container {
    width: 65%;
    height: 72%;
    margin: 0 15px 15px 15px;
    background: #ffff;
    border-radius: 12px;
    padding: 20px 30px;
  }
  .table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 10px;
    height: 9%;
  }
}
