.order_details_screen_container {
    padding: 10px 15px;
    margin-bottom: 30px;
    width: 300px;
    .order_details_screen_wrapper {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 20px;
        border-radius: 16px;

        .order_details_saree_heading {
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #0F182E;
        }

        .order_details_screen_image_header {
            margin-top: 20px;
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #0F182E;

        }

        .order_details_screen_image_flex_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap:7px;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .order_details_saree_image_property {
            border-radius: 18px;
            object-fit: cover;
        }

        .order_details_screen_info_wrapper_heading {
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #0F182E;
            margin-bottom: 12px;

        }

        .order_details_screen_info_list_flex_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .order_details_screen_info_list_heading {
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #BABABA;
            }

            .order_details_screen_info_list_answer {
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: right;
                color: #000000;

            }
        }

        .order_details_screen_notes_wrapper {
            margin-top: 15px;
        
            .order_details_screen_notes_flex_wrapper{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .order_details_screen_notes_heading {
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #0F182E;
            }
            .order_details_screen_edit_notes_wrapper{
                margin-top: 8px;
            }

            .order_details_screen_notes_answer {
                margin-top: 5px;
                margin-left: 1px;
                font-family: Open Sans;
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: -0.09099999815225601px;
                text-align: left;
                color: #000000;
                max-width: 280px;
                word-break: break-all
            }
           
            
        }
    }
}