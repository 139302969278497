@import '../../index.scss';

.home_container {
  .home_header {
    font-family: Open Sans;
    font-size: 30px;
    font-style: italic;
    font-weight: 800;
    line-height: 43px;
    letter-spacing: 0.105em;
    color: $primaryButtonColor;
    text-align: center;
    // margin-top: 45px;
    padding-top:45px ;
    margin-bottom: 20px;
  }
  .home_header_logo{
    width:30%;
  }
  .home_screen_wrapper {
    height: 90vh;
    overflow-y: scroll;
  }
  .home_wrapper {
    height: 85vh;
    overflow-y: auto;
    height: calc(100vh - 120px);

    .home_subheadings {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
    }
    .home_subheadings_today{
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left: 0px;
    }

    .home_deals_container {
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0px;
      display: flex;
      width: 100%;
      height: auto;
      align-items: center;
      flex-direction: column;

      .home_deals_wrapper {
        // position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 16px;
        // padding-left:14px;
        .swiper-pagination{
          bottom: 6px !important;
        }

        .home_deals_wrapper img{
          width: auto;
          height:100%;
          object-fit: cover;
        
        }
        

        .home_deals_discount_container {
          position: absolute;
          top: 20px;
          right: 20px;
          text-align: right;
          display: flex;
          flex-direction: column;

          .home_deals_discount_text {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 700;
            color: $buttonTextColor;
          }

          .home_deals_discount_percentage {
            font-family: 'Poppins';
            font-size: 42px;
            font-weight: 400;
            color: $buttonTextColor;
          }

          .home_deals_discount_type {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            color: $secondaryLightColor;
          }
        }
        .swiper-pagination{
          position: absolute;
          bottom: 30px;

          .swiper-pagination-bullet {
           width: 8px;
           height: 8px;
          //  border-radius: 8/2;
           background-color: white !important;
           display: inline-block;
         }
        }

        .home_deals_saree {
          width: 100%;
          height: 100%;
        }
      }
    }

    .home_order_status_container {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      .home_order_status_header {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 100%;
        display: flex;
        align-items: center;

        .home_order_status_heading {
          font-family: 'Poppins';
          font-size: 18px;
          font-weight: 400;
          width: 100%;
          display: flex;
          align-items: center;
        }

        .home_order_status_seeall_text {
          width: 15%;
          cursor: pointer;
          font-family: 'Poppins';
          font-size: 12px;
          font-weight: 400;
          color: $primaryLinkColor;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
        }
      }

      .home_order_status_wrapper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        overflow-x: auto;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        overflow-y: hidden;
        .order_status_wrap {
          width: 280px;
          height: 275px;
          display: flex;
          flex-direction: row;
          border-radius: 16px;
          margin-right: 20px;
          margin-top:10px;

        }
        .order_status_wrap_full {
          // width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .booking_button_container {
      position: relative;
    }

    .home_sample_container {
      // padding: 20px;
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      margin-top: 10px;
      .home_screen_sample_work_wrapper{
        width: 100%;
        height: 100%;
        overflow-x: auto;
        padding-bottom: 60px;
        padding-left: 20px;
      }
      .home_sample_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: no-wrap;
        // overflow-x: auto;
        justify-content: flex-start;
        gap: 10px;
        // margin-bottom: 20px;
      }
    }
        .order_empty {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    
          .order_empty_text {
            padding-top: 25px;
            font-size: 15px;
            color: #4f4f4f;
            font-family: 'Poppins';
    
    
          }
        }
  }
  .booking_button {
    width: 38%;
    border-radius: 24px;
    left: 115px;
    position: absolute;
    height: 50px;
    bottom: 95px;
    right: 0;
    z-index: 9999;
    box-shadow: 0px 2px 9px 1px $primaryButtonColor
  }
  .home_footer_container {
    height: 150px;
    width: 100%;
    position: fixed;
    position: relative;
    bottom: 0px;

    .home_footer_button_container {
      padding: 20px;
      width: 100%;
      background: transparent;
      display: flex;
      justify-content: center;

      .book_now_button {
        width: max-content;
        border-radius: 24px;
        font-weight: 500;
      }
    }
  }

  .percentage {
    font-size: 16px;
    font-weight: 400;
  }
}
.button_wrapper_position {
  position: relative;
}
.home_screen_saree_image{
  object-fit: contain;
  width: 100%;
  height: 160px;
}

