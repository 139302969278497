@import '../../../index.scss';

.checkbox {
  // height: 24px;
  // width: 24px;
  background: $inputBackground;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  &:hover {
    cursor: pointer;
  }
}
.checked_icon_wrapper {
  height: 15px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .checked{
    border: 1px solid $primaryButtonColor;
    border-radius:16px;
    padding:10px 15px;
    
  }
  .unchecked{
    border:1px solid #e8e8e8;
    border-radius:16px;
    padding:10px 15px;
   



  }
  &:hover {
    cursor: pointer;
  }
  
}
