@import '../../index.scss';

.stepper_details_order_status_container {

  // padding: 20px 15px;
  .details_order_status_wrapper {
    //   box-shadow: 0px 0 10px #f3f3f3;
    //   border-radius: 14px;
    //   padding: 15px 10px;
    //   background-color: red;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .details_order_status_element {
      display: flex;
      flex-direction: column;
      gap: 4px;

      // background-color: pink;
      // margin-left: 10px;
      .details_checkbox_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        //   justify-content: center;
        width: 170%;
        padding-left: 20px;

        //   background-color: black;
        .status_active {
          width: 18px;
          height: 18px;
        }

        .dashed_line {
          width: 50px;
          height: 20px;
          // background-color: aqua;
        }
      }
    }
  }

  .details_dashed_line {
    width: 100%;
    border: 1px dashed $primaryGreenBorder;
  }
  .details_disable_line {
    width: 100%;
    border: 1px dashed #C2C2C2;
  }

  .details_order_status_text {
    // padding-top: 20px ;
    padding-bottom: 20px;
    // margin-top: 10px;
    font-weight: 400;
    font-size: 10px;
    text-align: center;


  }
  .stepper-style{
    background-color: aqua !important;
  }
}