@import '../../../index.scss';

.circular_progress_text {
  //font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circular_progress_value {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 24px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $socialBtnColor;
}
