@import '../../../index.scss';
.booking_detail_container {
  width: 100%;
  height: 100%;
  display: flex;

  .sidebar-header {
    width: 20%;
  }
  .bookings_wrapper {
    width: 80%;
    background: #f6f6f6;
    min-height: 100vh;
  }
  .booking_detail_wrapper {
    width: 95%;
    margin: auto;
    height: 80%;
    background: #ffff;
    border-radius: 12px;
    padding: 20px 30px;
  }
  .table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9%;
  }

  .booking_header_id {
    width: 100%;
    //styleName: headline/h5 - 24;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    margin: 10px 10px 15px 10px;
  }
  .booking_detail {
    width: 100%;
    display: flex;
    // justify-content: space-between;
  }
  .booking_details_content {
    width: 45%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .booking_details {
    display: flex;
    gap: 20px;
  }
  .booking_details_user {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 40%;
  }
  .booking_get_delivery_address {
    margin: 10px 0;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .booking_customer_status {
    display: flex;
    gap: 20px;
  }
  .booking_details_address {
    display: flex;
    flex-direction: column;
    gap:1px;
  }
  .get_delivery_address_text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    width: 350px;
  }
  .booking_date {
    text-align: left;
  }
  .booking_status {
    width: 56%;
    text-align: left;
  }
  .booking_customer {
    width: 30%;
    text-align: left;
  }
  .booking_detail_down {
    display: flex;
  }

  .booking_header {
    //styleName: caption/caption 2 - 12;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
    margin-bottom: 7px;
  }
  .booking_content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .booking_delivery_address_value {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  .order_summary_header {
    //styleName: headline/h6 - 18;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
  }
  .order_summary_wrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 2px;
  }
  .order_summary_img {
    height: 122px;
    width: 121px;
    margin: 10px 2px;
    object-fit: cover;
  }
  .order_summary_img img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
  .order_summary_content {
    width: 60%;
    margin: 5px 2px;
  }
  .order_summary_content_header {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0;
  }
  .order_summary_quantity_header {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #808191;
  }
  .order_items_value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 5px 5px;
  }
  .order_items {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
  }
  .order_items_amount {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #808191;
  }
  .order_amount {
    //styleName: caption/caption 2 - 12;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
  }
  .order_summary_quantity {
    margin: 8px 0;
  }
  .order_summary_price_header {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
    color: #808191;
  }
  .order_price {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px 2px;
  }
  .line {
    height: 1px;
    width: 100%;
    background: #e4e4e4;
  }
  .order_summary_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order_summary_total_title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
    margin-left: 20px;
  }
  .order_summary_total_price {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    margin: 7px 0;
  }
  .booking_reschedule {
    width: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .booking_reschedule_button {
    margin: 10px 0;
    height: 56px;
    width: 180px;
  }
  .booking_refund_button {
    margin: 10px 0;
    height: 56px;
    width: 180px;
  }
  .booking_pleater_customer_detail {
    width: 28%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e4e4e4;
    gap: 20px;
    height: 480px;
    overflow: auto;
  }
  .booking_pleater_details {
    width: 90%;
    margin-left: 25px;
  }
  .booking_customer_details {
    width: 90%;
    margin-left: 25px;
  }
  .booking_pleater_header {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .booking_customer_header {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .booking_contents {
    margin: 10px 2px;
  }
  .booking_detail_content {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
  }

  .booking_profile_detail {
    display: flex;
    align-items: center;
  }
  .booking_profile_picture {
    width: 40px;
    height: 40px;
  }
  .booking_name {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
  }
  .booking_detail_address {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
  }
  .booking_email,
  .booking_mobile,
  .booking_address {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
  }

  .booking_details_unpaid_active {
    padding: 10px 15px;
    background: rgba(244, 81, 30, 0.1);
    color: #f4511e;
    border-radius: 4px;
  }

  .booking_details_unpaid_active::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #f4511e;
    margin-right: 10px;
  }
  .booking_details_accepted_active {
    padding: 8px 15px;
    background: rgba(67, 174, 129, 0.1);
    color: #43ae81;
    border-radius: 4px;
  }

  .booking_details_accepted_active::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #43ae81;
    margin-right: 10px;
  }
  .booking_details_onmyway_active {
    padding: 8px 12px;
    background: rgba(253, 159, 0, 0.1);
    color: #fd9f00;
    border-radius: 4px;
  }

  .booking_details_onmyway_active::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #fd9f00;
    margin-right: 10px;
  }
  .view_direction {
    margin-top: 15px;
    width: 80%;
  }
  .css-1xn7on6-control {
    padding: 0px !important;
  }
  .css-1dk33hv-control {
    padding: 0px !important;
  }
  .css-qs29zf-control {
    padding: 0px !important;
  }
  .css-1bdpfvf-control {
    padding: 0px !important;
  }
  .css-grj90j-control {
    padding: 0px !important;
  }
  .css-1w1zjbw-control {
    padding: 0px !important;
  }

  .css-b49die-control {
    padding: 0px !important;
  }
  .css-uy61kx-control {
    padding: 0px !important;
  }
  .css-6j8wv5-Input {
    padding: 0px !important;
  }
  .css-66deel-control {
    padding: 0px !important;
  }
  .css-u1lsry-control {
    padding: 0px !important;
  }
  .css-1j9mvd7-control {
    padding: 0px !important;
  }
  .css-1wvopl7-control {
    padding: 0px !important;
  }
.quantity_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .quantity_input {
    background-color: #efefef;
    outline: 2px solid #f7f7f7;
    border-radius: 5px;
    height: 30px;
    border: 0;
    padding-left: 10px;
  }
  .quantity_input:focus {
    background-color: $buttonTextColor !important;
    color: $inputTextColor !important;
    outline: 2px solid #1d7bdf;
  }
  .quantity_button {
    background-color: rgb(161, 52, 45);;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color:rgb(255, 255, 255);
    cursor: pointer;
  }
}
.cancel_reasons_wrapper {
  display:flex ;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  .cancel_reasons_text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
}
.border_cancel_reasons {
  border-top: 2px dotted #808191;
  padding:10px 0;
}

}