@import '../../../index.scss';
.user_detail_container {
  width: 100%;
  height: 100%;
  display: flex;

  .react-switch-bg{
    margin: 0px !important;
  }
  .react-switch-handle{
    width: 26px !important;
    height: 26px !important;
  }
  
  .sidebar-header {
    width: 20%;
  }
  .users_wrapper {
    width: 80%;
    background: #f6f6f6;
    min-height: 100vh;
  }
  .user_detail_wrapper {
    margin: 20px 0 20px 20px;
    width: 80%;
    height: 54%;
    background: #ffff;
    border-radius: 12px;
    padding: 20px 30px;
  }
  .table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9%;
  }
  .user_header {
    display: flex;
    align-items: center;
  }
  .user_title {
    width: 50%;
    //styleName: headline/h5 - 24;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    margin: 10px 10px 15px 10px;
  }
  .user_role_area {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 20px;
    .css-162c06t-control {
      padding: 3px;
    }
    .css-10le8a-control {
      padding: 3px;
    }
    .css-qc6sy-singleValue{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .user_role {
    width: 50%;
  }
  .user_area {
    width: 50%;
  }
  .user_detail {
    padding-top:20px ;
    margin: 20px 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .booking_pleater_customer_detail {
    width: 32%;
    border-left: 1px solid #e4e4e4;
  }
  .user_details_content {
    width: 40%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .user_detail_upper {
    display: flex;
    gap: 10px;
  }
  .user_date {
    text-align: left;
    width: 100px;
  }
  .user_status {
    text-align: left;
    width: 200px;
  }
  .user_customer {
    text-align: left;
    width: 90px;
  }
  .user_detail_down {
    display: flex;
  }

  .user_order {
    width: 120px;
  }
  .user_delivery_address {
    width: 300px;
  }
  .user_header {
    //styleName: caption/caption 2 - 12;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
    margin-bottom: 7px;
  }
  .user_content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 20px;
    margin-left: 1px;
  }
  .user_delivery_address_value {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  .user_summary_header {
    //styleName: headline/h6 - 18;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    margin-top: 20px;
  }
  .order_summary_wrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 2px;
  }
  .order_summary_img {
    height: 122px;
    width: 121px;
    margin: 10px 2px;
    object-fit: cover;
  }
  .booking_pleater_notification{
    margin-top: 50px;
    width: 40%;
  }
  .booking_notification_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 18%;
  }
  .order_summary_img img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
  .order_summary_content {
    width: 60%;
    margin: 5px 2px;
  }
  .order_summary_content_header {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0;
  }
  .order_summary_quantity_header {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #808191;
  }
  .order_items_value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 5px 5px;
  }
  .order_items {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
  }
  .order_items_amount {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #808191;
  }
  .order_amount {
    //styleName: caption/caption 2 - 12;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
  }
  .order_summary_quantity {
    margin: 8px 0;
  }
  .order_summary_price_header {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
    color: #808191;
  }
  .order_price {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px 2px;
  }
  .line {
    height: 1px;
    width: 100%;
    background: #e4e4e4;
  }
  .order_summary_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order_summary_total_title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
    margin-left: 20px;
  }
  .order_summary_total_price {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    margin: 7px 0;
  }

  .user_pleater_customer_detail {
    width: 28%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e4e4e4;
    gap: 20px;
  }
  .user_details {
    width: 90%;
    margin-left: 25px;
  }
  .user_customer_details {
    width: 90%;
    margin-left: 25px;
  }
  .user_detail_header {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .user_customer_header {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .user_contents {
    margin: 10px 2px;
  }
  .user_detail_content {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 16px 0;
  }

  .user_profile_detail {
    display: flex;
    align-items: center;
  }
  .user_profile_picture {
    width: 40px;
    height: 40px;
  }
  .user_name {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
  }
  .user_detail_address {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
  }
  .user_email,
  .user_mobile,
  .user_address {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
    line-height: 20px;
  }
}
