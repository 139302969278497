@import '../../index.scss';
.details_container {
  .details_subheading {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .order_details_scroll_wrapper{
    display: flex;
    overflow-x: auto;
    margin-top: 10px;
    overflow-y: hidden;
    width: auto;
    cursor: pointer;
  }
  .details_saree_container {
    padding-bottom: 20px;
    .details_saree_header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .details_saree_price {
        display: flex;
        gap: 5px;
        align-items: center;
        .details_saree_price_currency {
          font-family: 'Poppins';
          font-size: 10px;
          font-weight: 400;
        }
        .details_saree_price_value {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 600;
        }
      }
      .details_saree_count {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
      }
    }
    .details_saree_gallery {
      display: flex;
      flex-direction: row;
      gap: 10px;
      overflow-x: auto;
      margin: 0px 10px;
      .details_saree_picture {
        object-fit: cover;
        display: flex;
        height: 85px;
        width: 85px;
        display: flex;
        gap: 10px;
        .__react_modal_image__header {
          height: 100px;
          padding-top: 40px;
        }
        .saree_picture {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
  .details_wrapper {
    border-radius: 30px 30px 0px 0px;
    box-shadow: 0px -30px 30px 0px #f3f3f3;
    .details_saree_info_container {
      padding: 20px 15px;
      .details_info_container {
        .details_info_type_value {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          padding: 8px 0px;
          .details_info_type {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 600;
            color: $secondaryLightText;
            width: 30%;
          }
          .details_info_value {
            width: 68%;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 600;
            text-align: right;
          }
          .details_info_value_notes {
            width: 68%;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 600;
            text-align: right;
            word-break: break-all;
          }
        }
      }
    }
    .details_order_status_container {
      padding: 20px 15px;
      .details_order_status_wrapper {
        box-shadow: 0px 0 10px #f3f3f3;
        border-radius: 14px;
        padding: 15px 10px;
        position: relative;
        .details_order_status_element {
          display: flex;
          gap: 8px;
          .details_checkbox_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 10%;
            .status_active {
              width: 18px;
              height: 18px;
            }
            .dashed_line {
              width: 50px;
              height: 20px;
            }
          }
        }
      }
    }
    .details_reviews_container {
      padding: 20px 15px 30px 15px;
      .details_reviews_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .details_reviews_text {
          font-size: 12px;
          font-weight: 400;
        }
        .add_reviews_button {
          color: $addButtonTextColor !important;
          background: $buttonTextColor;
          border: 1px solid $addButtonTextColor;
          padding: 5px;
          border-radius: 14px;
          width: 100px;
          height: 30px;
        }
      }
    }
  }
  .details_footer_container {
    height: 70px;
    width: 100%;
  }

  .details_checkbox_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    .status_active {
      width: 18px;
      height: 18px;
    }
    .status_active_cancel {
      width: 23px;
      height: 23px;
    }
    .dashed_line {
      width: 42px;
      height: 20px;
    }
  }
}

.details_dashed_line {
  width: 0.5px;
  border: 1px dashed $primaryDashedBackground;
  height: 100%;
}
.details_order_status_text {
  // padding-top: 20px ;
  padding-bottom: 20px;
  // margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
}
.details_order_status_text_margin-top {
  padding-bottom: 20px;
  margin-top: 2px;
  font-weight: 400;
  font-size: 14px;
}
.details_order_status_text_flex {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  // padding-top: 20px ;
  padding-bottom: 20px;
  // margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
}
.details_main_wrapper {
  overflow: auto;
  height: calc(100vh - 70px);

}
.details_status_text_container {
  .details_description_text_container {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 10px 0px;
    // padding-bottom: 20px;
    .details_description_text {
      font-size: 12px;
      font-weight: 400;
      color: $primaryGrayColor;
    }
    .edit_order_button {
      height: 26px;
      width: 103px;
      border-radius: 14px;
      padding: 0px;
      background: $buttonTextColor;
      color: $primaryButtonColor;
      border: 1px solid $primaryButtonColor;
      display: none;
      // font-weight: 400;
    }
  }
}
.padding_none {
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
}
.order_details_delivery_saress {
  padding: 10px;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.saree_picture_delivery {
  height: 70%;
  width: 70%;
  border-radius: 14px;
}
.saree_picture_delivery:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  width: 100%;
  height: 100%;
}
.__react_modal_image__icon_menu a:nth-child(2) {
  display: none !important;
}
.__react_modal_image__icon_menu a:nth-child(1) {
  display: none !important;
}
.review_screen_container {
  margin: 0 20px;
}
.review_screen_wrapper {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 15px;
  height: auto;
  width: auto;
}
.review_screen_name_title {
  font-weight: 500;
  font-size: 16px;
}

.review_screen_content {
  font-weight: 400;
  font-size: 14px;
}
.review_screen_star {
  margin-left: -3px;
}

.review_screen_wrapper {
  margin-bottom: 10px !important;
}
.review_screen_heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.details_order_cancel_text {
  margin-top: 2px !important;
}
.details_order_cancel_button {
  margin-top: -1px !important;
  padding: 5px 10px;
  border-radius: 14px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: $primaryButtonColor;
  border: 1px solid $primaryButtonColor;
}
.order_details_contact_heading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 10px;
  margin-top: 12px;
}
.order_details_screen_contact_wrapper {
  margin-left: 10px;

  .order_details_screen_contact_flex_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 15px;
  }
  .order_details_screen_image_name_flex_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .order_details_screen_contact_image {
    width: 35px;
    height: 35px;
    border-radius: 14px;
    object-fit: cover;
  }
  .order_details_screen_name_heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  .order_details_screen_call_wrapper {
    border: 1px solid #3db2ff;
    padding: 5px 25px;
    border-radius: 16px;
  }
  .order_details_screen_call_content {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #3db2ff !important;
  }
}
.details_order_order_cancelled_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.order_details_screen_map_tracking_container {
  padding: 20px 17px;
  .order_details_screen_map_heading{
    margin-bottom: 10px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
  }
  .order_details_screen_map_tracking_wrapper {
    box-shadow: 0px 0 10px #f3f3f3;
    padding: 10px 10px;
    border-radius: 14px;
    .order_details_screen_map {
      width: auto;
    }

  }
}

.order_details_scroll_container {
  .order_details_header {
      padding: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 35px;

      .order_details_navbutton_size {
          width: 48px;
          height: 48px;
          border-radius: 16px;
      }

      .order_details_nav_header_content {
          font-family: Open Sans;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #0F182E;

      }
  }
  


  
}
.order_details_screen_help_whatsapp_container {
  position: absolute;
  bottom: 100px;
  margin-right: 5px;
  right: 10px;
  cursor: pointer;


  .order_details_screen_wrapper {
    background-color: $primaryButtonColor;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    padding: 6px 0;

    .order_details_screen_whatsaapp_button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}
.express_delivery_timing_content {
  padding-left: 36px;
  padding-top: 35px;
  padding-bottom: 5px;
  color: #0F182E;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */

}
.express_delivery_timing_content span {
  color: #3DB2FF;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  // margin-left: 5;
}
