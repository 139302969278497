@import '../../index.scss';
.booking_container {
  .loader {
    background-color: #00000040;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
  }
  .booking_scroll_container {
    height: 90vh;
    overflow-y: auto;
    padding-bottom: 20px;
    .booking_subheading {
      font-family: Open sans;
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 7px;
    }
    .booking_header {
      padding: 10px;
    }
    // background-color:red;
    .booking_addsaree_container {
      // height: 80vh;
      padding: 10px;
      // overflow-y: scroll;
      .booking_addsaree_header {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .booking_addsaree_price_container {
          display: flex;
          align-items: flex-end;
          gap: 3px;
          .booking_addsaree_price_currency {
            font-size: 12px;
            font-weight: 400;
            padding-bottom: 2px;
          }
          .booking_addsaree_price_value {
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
      .booking_addsaree_button {
        // padding: 15px 0px;
        .addsaree_button {
          background: $primaryBackgroundColor;
          color: $addButtonTextColor !important;
        }
      }
      .booking_addsaree_wrapper {
        padding: 0px 10px;
        .addsaree_container {
          margin: 13px 0px;
          .addsaree_remove_text {
            font-family:'Poppins';
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            line-height: 15px;
            letter-spacing: 0px;
            text-align: center;
            width: 0% !important;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 10px;
            padding-right: 8px;
            color: $primaryButtonColor;
            cursor: pointer;
          }
          .addsaree_wrapper {
            background: $primaryBackgroundColor;
            border-radius: 16px;
            padding: 10px;
            position: relative;
            .addsaree_top_wrapper {
              // border-bottom: 1px solid $borderolor;
              padding: 8px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;
              overflow-x: auto;
            }
            .addsaree_camera_container {
              display:flex;
              justify-content: flex-start;
              align-items: center;
              // overflow-x: auto;
              gap: 9px;
            }
            .upload_saree_image_wrapper {
              display: flex;
              gap: 10px;
              // overflow-x: auto;
            }
            .upload_image_position {
              position: relative;
            }
            .upload_saree_image {
              height: 42px;
              width: 42px;
              border-radius: 18px;
              margin-right: 4px;
            }
            .cancel_button {
              position: absolute;
              top: -4px;
              right: 0px;
              z-index: 9999;
            }
            .addsaree_bottom_wrapper {
              padding: 8px;
              display: flex;
              justify-content: space-between;
              .addsaree_saree_count_container {
                font-family: Open sans;
                font-size: 14px;
                font-weight: 400;
              }
              .addsaree_saree_price_container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .addsaree_price_currency {
                  font-family: 'Poppins';
                  font-size: 10px;
                  font-weight: 400;
                  padding-right: 3px;
                }
                .addsaree_price_value {
                  font-family: 'Poppins';
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
    .booking_addnotes_container {
      padding: 10px;
      margin: 10px;
      margin-top: 20px;
    }
    .booking_address_container {
      background-color: #F4F6F8;
      border-radius: 16px;
      margin: 20px;
   
      .add_address_button {
        background: $primaryBackgroundColor;
        color: $addButtonTextColor;
      }
    }
    .booking_offers_container {
      padding: 20px;
      .booking_offers_wrapper {
        position: relative;
        width: 100%;
        height: 100px;
        .offer_card_image {
          width: 100%;
          height: 100%;
        }
        .booking_offer_card_container {
          position: absolute;
          right: 50px;
          top: 30px;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          .booking_offer_percentage {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 400;
            color: $buttonTextColor;
          }
          .booking_offer_type {
            font-family: 'Poppins';
            font-size: 10px;
            font-weight: 400;
            color: $buttonTextColor;
            word-wrap: wrap;
          }
        }
        .apply_button {
          background: $buttonTextColor;
          color: $primaryButtonColor;
          padding: 10px 15px !important;
        }
      }
    }
    .booking_coupons_container {
      padding: 0px 20px 20px 20px;
      width: 100%;
      .booking_coupon_toptext {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 8px;
        font-family: 'Poppins';
        font-size: 13px;
        font-weight: 400;
        color: $secondaryLightText;
      }
      .booking_coupon_input_button {
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
        .booking_coupon_input {
          width: 55%;
          border: 2px dashed $dashedBorderColor;
          border-radius: 6px;
          display: flex;
          input:not(:focus)::placeholder {
            opacity: 1 !important;
          }
          .coupon_input {
            padding: 0px 5px !important;
            border: 0px;
            margin: 0px;
            text-align: center;
          }
          input::placeholder {
            text-align: center;
          }
        }
        .booking_coupon_use_button {
          width: 40%;
        }
      }
      .booking_coupon_bottom_wrapper {
        padding: 10px 0px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .booking_coupon_bottom_text {
          font-family: Poppins;
          font-size: 13px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;
          color: #a4b0be;
        }
      }
    }

    .booking_express_delivery_container {
      padding: 2px 20px;
      margin-top: 30px;
      .booking_address_flex_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .booking_subheading_change {
        font-size: 13px;
      }
      .booking_subheading {
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #0f182e;
        margin-bottom: 8px;
        font-style: normal;
      }
      .booking_delivery_wrapper {
        border: 1px solid $borderColor;
        border-radius: 16px;
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        padding: 4px 12px;
      }
      .booking_delivery_note {
        width: 60%;
        .booking_delivery_note_text {
          font-family: 'Poppins';
          font-size: 12px;
          font-weight: 400;
        }
        .booking_delivery_note_charges {
          font-family: 'Poppins';
          font-size: 10px;
          font-weight: 400;
          margin-top: 5px;
          color: #bfbfbf;

          // color: $secondaryLightText !important;
        }
      }
    }
    .booking_payment_container {
      padding: 20px;
      .booking_payment_wrapper {
        border: 1px solid $borderColor;
        padding: 20px;
        border-radius: 16px;
        display: flex;
        margin-top: 10px;
        .booking_payment_icon {
          width: 12%;
        }
        .booking_payment_type {
          width: 80%;
        }
        #checkbox {
          height: 16px;
          width: 16px;
          left: 0px;
          top: 0px;
          border-radius: 4px;
        }
      }
    }
    .delivery_note{
      font-size: 12px;
      color:#636262;
      padding: 10px 20px 0px 20px;
    }
    .help{
      font-size: 14px;
      color:#636262;
      padding: 0px 40px 0px 40px;
      text-align: center;
      .whatsapp{
        font-size: 16px;
        color:#25D366 !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .booking_billing_container {
      padding: 30px 20px 0px 20px;
      .booking_billing_wrapper {
        .booking_billing_type_value {
          display: flex;
          justify-content: space-between;
          margin: 8px 0px;
          .booking_billing_type {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
          }
          .booking_billing_value {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            color: $secondaryLightText;
          }
        }
      }
      .booking_billing_footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $borderColor;
        padding-top: 15px;
        // padding: 15px 0px;
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 600;
      }
    }
    .booking_footer {
      // display: flex;
      // justify-content: space-between;
      // border-top: 1px solid $borderColor;
      padding: 10px 0px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      // box-shadow: 0px 1px 4px 0px #00000029;
      .booking_price_text {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        color: $secondaryLightText;
      }
      .booking_price_value {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 600;
      }
      .booking_next_button {
        width: 50%;
        margin-top: 5px;
      }
    }
    .add_notes_input {
      height: 90px;
      outline: none !important;
      border: 1px solid $borderColor;
    }
  }
  .booking_address_flex_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .booking_address_change {
    font-family:'Poppins';
    margin-top: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: hsla(4, 55%, 42%, 1);
    cursor: pointer;
  
  }
  .booking_address_content_heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #2f3542;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  .booking_address_content_text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
  .coupon_code_container {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(186, 186, 186, 0.25);
    padding: 12px 18px 8px 18px;
    margin-top: 20px;
    .coupon_code_flex_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .coupon_code_discount {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      margin-top: 5px;
    }
    .coupon_code_discount_icon {
      width: 28px;
      height: 28px;
    }
    .coupon_code_discount_text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #1d1d1d;
      padding-top: 3px;
    }
    .coupon_code_apply_button {
      height: 34px !important;
      width: 130px !important;
      left: 0px;
      top: 0px;
      margin-top: 9px;
      border-radius: 16px !important;
    }
    .coupon_code_horizontal_line {
      margin-top: 30px;
      margin-bottom: 25px;
      border-top: 1px solid #e8e8e8;
    }
    .coupon_code_remove_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .coupon_code_content {
      font-family:'Poppins';
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #1d1d1d;
    }
    .coupon_code_remove_button {
      font-family:'Poppins';
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #ff3d00;
    }
  }
}
.booking_screen_question_container {
  padding: 20px;
  .booking_screen_question_checkbox_section_1 {
    .body_size_slider {
      // padding-right: 16px;
    }
    .booking_screen_checkbox_wrap {
      display: flex;
      gap: 15px;
      padding-top: 18px;

      .booking_screen_checkbox {
        flex-direction: column;
        display: flex;
        .booking_screen_input_checkbox {
          height: 90px;
          width: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        .booking_screen_checkbox_text {
          padding-top: 14px;
          padding-left: 17px;
        }
      }
    }
  }
  .booking_screen_question_checkbox_section_2 {
    margin-top: 33px;
    .booking_screen_slider_range {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      padding-top: 5px;
    }
  }

  .booking_screen_question_title {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }
  .booking_screen_question_title_range{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
    
  }
  .booking_screen_num_of_pleat_range{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    margin-right: 7px;
  }
  // .booking_screen_checkbox_flex_wrapper {
  //   padding: 0 4px;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-start;
  //   align-items: center;
  //   flex-direction: row;
  //   margin-top: 15px;
  //   gap: 10px;

  //   .booking_screen_section_content {
  //     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //   }
  //   .booking_screen_section_content_height {
  //     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //   }
  // }

  .booking_screen_question_checkbox_2 {
    margin-top: 20px;
  }
  .booking_screen_checkox_section_2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 11px;
    flex-wrap: nowrap;
  }
  .booking_screen_slider_container {
    width: 100%;
  }
}

.booking_screen_warning_message_remove_coupon {
  font-size: 14px;
  color: #ff3d00 !important;
  padding: 10px;
  text-align: center;
  font-family: 'Poppins';
}
.slider-horizontal {
  // padding-bottom: 10px;
  // padding-top: 5px;

  .rangeslider {
    display: block;
    box-shadow: none;
    .rangeslider__handle-tooltip {
      visibility: visible;
      width: 40px;
      height: 40px;
      text-align: center;
      position: absolute;
      background-color: $primaryButtonColor;
      transition: all 100ms ease-in;
      display: inline-block;
      color: white;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }
  .rangeslider__fill {
    background-color: $primaryButtonColor;
    top: 0px;
    height: 100%;
    border-radius: 10px;
  }

  .rangeslider__handle {
    background-color: transparent;
    border-width: 0px;
    box-shadow: none;
    height: 20px;
    left: 0px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rangeslider__handle::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: $primaryButtonColor;
    border-width: 0px;
    border-color: transparent;
  }
  .rangeslider-horizontal {
    height: 3px;
    border-radius: 10px;
}
  .rangeslider__handle-tooltip::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $primaryButtonColor !important;
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
  }
}
.booking_screen_add_saree_notify{
  font-family:'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: right;
  color: #969191;
  
}


.booking_screen_hip_size_wrapper {
  margin-top: 15px;
  padding: 0 8px;

  .booking_screen_hip_size_heading_flex_wrapper {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hip_size_heading {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #0F182E;

    }
     .booking_screen_question_title_range{
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 600;
      margin-right: 6px;
      
    }
  }
  .booking_screen_hip_size_range_slider_wrapper{
    margin-top: 20px;
  }
}

.booking_screen_pleat_wrapper {
  margin-top: 15px;
  padding: 0 8px;

  .booking_screen_pleat_range_slider_flex_wrapper {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;

    .pleat_heading {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #0F182E;

    }
    .booking_screen_num_of_pleat_range{
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 600;
      margin-top: 22px;
      margin-right: 11px;
      
    }
     .booking_screen_num_of_pleat_range1{
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 600;
      margin-top: 22px;
      margin-right: 8px;
      
    }
  }
}
.booking_screen_body_height_wrapper {
  margin-top: 20px;
  padding: 0 8px;

  .booking_screen_body_height_heading {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0F182E;
  }

  .booking_screen_checkbox_flex_wrapper {
    padding: 0 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
    gap: 10px;

    .booking_screen_section_content {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .booking_screen_section_content_height {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #0F182E;
     margin-top: -2.5px !important;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }

}
.booking_screen_notes_wrapper {
  margin-top: 20px;
  padding: 0 8px;
  margin-bottom: 15px;

  .booking_screen_notes_heading {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0F182E;
  }

  .booking_screen_notes {
    margin-top: 10px;
  }
}

.booking_screen_saree_image_heading_flex_wrapper{
   margin-bottom: 12px;
   margin-top: 5px;
   padding: 0 8px;
   display: flex;
   justify-content: space-between;
   align-items: center;
.booking_screen_saree_image_heading{
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
}
.addsaree_saree_price_container{
  display: flex;
  justify-content: flex-start;
  gap:5px;
  padding: 0 8px;
  align-items: center;
}
.addsaree_price_currency {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
}
.addsaree_price_value {
  font-size: 14px;
  font-weight: 700;
}
.addsaree_saree_count_container{
  width: 100px;
}
.booking_screen_apply_all_checkbox_wrapper {
  margin:10px 0;
  padding: 0 8px;

  .booking_screen_checkbox_apply_all_flex_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .booking_screen_checkbox_content {
      margin-top: -3px;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #0F182E;
    }

  }
}
// accordion
.accordion-wrapper .accordion-item .accordion-panel .panel-open{
  background: #F4F6F8 !important;
  padding: 0 !important;

}

button.active{
  background-color: #F4F6F8 !important;
  color:  #0F182E !important ;
  border:none !important;
}
.accordion-wrapper .accordion-item h3.accordion-title button:hover{
  background-color: none !important;
}
.accordion-wrapper .accordion-item h3.accordion-title button {
  background: none !important;
  color: #0F182E !important;
  margin-top: 0 !important;
  padding: 0px!important;
  cursor: pointer;
}


span.plus {
  display: none !important;
}

// dunzo quote 
.dunzo_quote_container {
  padding: 30px 20px 0px 20px;

  .dunzo_quote_heading {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .dunzo_quote_flex_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .dunzo_quote_details_heading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
  }

  .dunzo_quote_details_data {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    color: #8e8e93;
  }
  .dunzo_quote_error_message{
    font-family: 'Poppins';
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 400;
  }
}

// booking map

.booking_screen_map_container{
  pointer-events: none !important;
  .map_loading_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
  }
  .booking_screen_map_wrapper{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 200px;
    background-color: #F4F6F8;
    border-radius: 16px;
  }
}


.accordion-wrapper{
  .accordion-item {
    h3.accordion-title{
      button {
      span.icon-wrapper{
        span.minus{
          content: url('../../assets/icons/accordion.svg') !important;
           animation: accordionArrow 1s linear 0s infinite alternate  !important;
            width:19px !important;
            position: absolute !important;
            height:19px !important;
            transform:rotate(180deg) !important;
            left:145px;
            cursor: pointer;
            top:60px;

            margin-top: -10px;
            @keyframes accordionArrow{
              0%{
                margin-top: 0px;
              
              }
              50%{
                margin-top: 5px;
               
              }
              100%{
                margin-top: 0px;
                
              }
            }
        }
      }
    }
    }
  }

}  

.hip_notes_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin: 15px 0;
}
.saree_upload_wrapper {
  width: 95%;
  height: 164px;
  border-radius: 12px;
  border: 1px dashed #3DB2FF;
  margin: 0 auto;
  margin-top: 23px;
  margin-bottom: 32px;
  background-color: #DEF2FF;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  .saree_upload_flex_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    .saree_upload_image {
      display: flex;
      justify-content: center;
      align-items: center;
    }
     .saree_upload_content {
      color: #3DB2FF;
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration-line: underline;
      text-underline-offset: 3px;
    }
  }

}

.saree_upload_show_image_wrapper {
  margin-top: 23px;
  margin-bottom: 32px;


  .saree_upload_show_image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 95%;
    height: 164px;
    margin: 0 auto;

  }

  .delete_saree_upload_button_wrapper {
    position: absolute;
    top: -10px;
    bottom: 0;
    right:-6px;

    .delete_saree_button_image {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.eye_wrapper {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 3px;
  cursor: pointer;
  right: 4px;
}
.hip_notes_content_wrapper {
  border: 2px dotted #9C312B;
  width: 100%;
  margin-top: 30px;
}
.hip_note_text_content {
  font-size: 12px;
  padding: 5px 10px;
  font-family: 'Poppins';
  text-align:center;
  color: #000000;
}
.courier_delivery_types_heading {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
}
.add_pincode_content_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding-top: 25px;
  padding-left: 20px;
}
.add_pincode_content {
  color: #0F182E;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 138.462% */
}

.add_pincode_content span {
  color: #3DB2FF;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;
}
.booking_screen_booking_button {
  padding: 0 20px;
  width: 100%;
  // padding-top: 10px;
}
.booking_button_disabled {
  background-color: #9C312B !important;
  color: #ffffff !important;
}


  



