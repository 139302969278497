.privacy_screen_container {
  .privacy_screen_header {
    height: 12vh;
  }
  .privacy_screen_content_container {
    padding: 0 30px;
    height: 78vh;
    overflow-y: scroll;
  }
  .privacy_scree_footer_conatiner {
    height: 10vh;
  }
}
