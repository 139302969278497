@import '../../../../index.scss';

.invite_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .invite_wrapper {
    padding: 20px;
    width: 100%;
    border-radius: 24px;
    .invite_header {
      display: flex;
      justify-content: space-between;
      .invite_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
    }
    .invite_subheading {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 10px;
    }
    .invite_send_button {
      display: flex;
      justify-content: space-around;
      padding-top: 30px;
    }
    .send_invite_button {
      margin: 0 0px !important;
      padding: 12px 40px !important;
    }
  }
}
.payout_model_wrapper {
  border-radius: 16px !important;
  width: 310px !important;
  padding: 9px !important;
}
.reschedule_wrapper {
  border-radius: 16px !important;
  width: 514px !important;
  padding: 9px !important;
  overflow-y: unset !important;

  .reschedule_button_warpper {
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
  }
  .reschedule_content {
    margin-left: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .pleater_title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #b2b3bd;
    margin: 0px 0 20px 0;
  }
}
.cancel_booking_modal_wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .cancel_booking_modal_flex_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    gap: 30px;
    margin-top: 20px;
  }
}

.cancel_booking {
  border-radius: 16px !important;
  width: 354px !important;
  padding: 9px !important;
  overflow-y: unset !important;
}

.user_address_wrapper {
  padding:20px;
  .user_address_heading {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }
  .user_address_field_wrapper {
    margin-top: 15px;
    .input_field_label {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #808191;
      margin-bottom: 10px;
    }
  }
  .user_address_map_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    width: 150px;
    cursor: pointer;
    .user_addres_map_location_content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: '#000000';
    }
  }

  .user_address_button_wrapper {
    margin-top: 20px;
    
  }
}
.location_content_wrapper {
  margin-top: 15px;
  padding: 0 10px;
  .location_content_text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
  }
}

.location_content_container {
  margin-top: 20px;
  gap:10px;
  .location_content_heading {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
  }
  .location_content_text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
    margin-top: 4px;
  }
  .latlng_heading {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #808191;
    margin-top: 4px;

  }
}

.notification_modal_wrapper {
  width: 100% !important;
  height: 80%;
  border-radius: 24px;
  padding: 30px;

  .notification_heading_content {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    color: #A1342D;
  }

  .notification_send_to_wrapper {
    margin-top: 24px;

    .notification_sendTo_heading {
      margin-bottom: 16px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #B2B3BD
    }

    .notification_send_to_title_heading {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 24px;
      color: #B2B3BD;
    }

    .notification_send_to_title {
      margin-top: 8px;
      .input_container  {
        background-color: transparent !important;
        outline-color: #e8e8e8 !important;
        outline-width: 1px;
      }
      .input_container .input {
        background-color: transparent !important;
      }
      .notification_send_to_title_input {
        height: 45px;
      }
      .focus{
        outline: 1px solid #A1342D!important;
      }
    }
    .notification_send_to_content_heading {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 24px;
      color: #B2B3BD;
      margin-bottom: 8px;
    }
    .notification_send_text_area {
      height: 167px;
    }
    .notification_textarea_focus {
      outline: 1px solid #A1342D;
    }
    .notification_send_to_button_wrapper {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      .notification_send_to_button_flex_wrapper  {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      .notification_button_width {
        width: 212px;
      }
    }
  }
}

.notification_modal_schedule_wrapper {
  width: 100% !important;
  height: 90%;
  border-radius: 24px;
  padding: 30px;
  
  .notification_schedule_heading_content {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    color: #A1342D;
  }

  .notification_schedule_days_heading {
    margin-top: 24px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #B2B3BD;
  }

  .notification_schedule_days_wrapper {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    .notification_schedule_days_flex_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-left: 5px;

      .notification_schedule_days_content {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #1B1D21;

      }
    }
    .disable_days_wrapper {
      opacity: 0.4;
    }
  }
    .notification_schedule_time_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      margin-top: 24px;
  
      .notification_time_picker_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
      }
  
      .notification_time_picker_heading {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #B2B3BD;
      }
      .react-time-picker__wrapper {
        background-color: transparent !important;
        border: 1px solid #E4E4E4 !important;
      }
    }
    .notification_schedule_title_heading {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #B2B3BD;
      margin-top: 24px;
    }
    .notification_schedule_title_wrapper {
      margin-top: 8px;
      .input_container {
        background-color: transparent !important;
        outline-color: #e8e8e8 !important;
        outline-width: 1px;
      }  
      .input_container .input {
        background-color: transparent !important;
      }
      .notification_send_to_title_input {
        height: 45px;
      }
      .focus {
        outline: 1px solid #A1342D!important;
      }
    }
    .notification_schedule_textarea_wrapper {
      margin-top: 24px;
      .notification_schedule_textarea_heading {
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          color: #B2B3BD;
        }
      .notification_send_text_area {
         height: 167px;
         margin-top: 8px;
       } 
       .notification_textarea_focus {
        outline: 1px solid #A1342D !important;
       }
    }
    .notification_schedule_button_wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 32px;
      .notification_schedule_button_flex_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        .notification_schedule_button_width {
          width: 212px;
        }
      }
    }

}

.admin_notification_detail {
  width: 100% !important;
  height: 50%;
  border-radius: 24px;
  padding: 30px;

  .notification_details_heading {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    color: #A1342D;
  }

  .notification_details_send_wrapper {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;

    .notification_details_sendTo_flex_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      flex-direction: column;

      .notification_details_sendTo_heading {
        //styleName: caption/caption 2 - 12;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #B2B3BD;
      }

      .notification_details_sendTo_content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: #11142D;

      }
    }
  }

  .notification_details_tile_heading {

    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #B2B3BD;
    margin-bottom: 8px;
    margin-top: 24px;

  }

  .notification_details_tile_content {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #11142D;

  }

  .notification_details_content_heading {
    margin-top: 24px;
    margin-bottom: 8px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #B2B3BD;


  }

  .notification_details_content {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #11142D;

  }
}

.coupon_modal_wrapper {
  width: 100% !important;
  height: 90%;
  border-radius: 24px;
  padding: 30px;

  .coupon_modal_heading {
    //styleName: headline/h5 - 24;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    color: #A1342D;
  }

    .coupon_modal_field_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px 36px;
  
      .focus {
        outline: 1px solid #A1342D;
      }
  
      .input_container {
        background-color: #E4E4E4;
  
      }
  
      textarea {
        min-height: 100px !important;
        background-color: #E4E4E4 !important;
      }
      .text_area_active {
        background-color: #ffff !important;
      }
  
      .input {
        background-color: transparent !important;
      }
  
      .coupon_modal_input_field_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
  
        .coupon_modal_input_heading {
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          color: #B2B3BD;
  
        }
  
        .coupon_modal_input_field {
          width: 350px;
        }
      }
  
      .coupon_modal_repeat_field_wrapper {
        display: flex;
        align-self: baseline;
        gap: 10px;
      }
  
      .coupon_modal_checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .coupon_modal_repeat_content {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        color: #1B1D21;
        display: flex;
        justify-content: center;
        align-items: center;
  
      }
  
      .text_area_active {
        font-weight: 400;
        font-size: 13px;
        font-family: 'Poppins';
      }
  
      .css-6qwycv-control {
        // background-color: #FFF;
      }
  
    }
  .coupon_modal_field_wrapper_two {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px 36px;

    .focus {
      outline: 1px solid #A1342D;
    }

    .input_container {
      background-color: #E4E4E4;

    }

    .input {
      background-color: transparent !important;
    }

    .coupon_modal_input_field_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;

      .coupon_modal_input_heading {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #B2B3BD;

      }

      .coupon_modal_input_field {
        width: 350px;
      }
    }

    .coupon_modal_repeat_field_wrapper {
      display: flex;
      align-self: baseline;
      gap: 10px;
    }

    .coupon_modal_checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .coupon_modal_repeat_content {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      color: #1B1D21;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .text_area_active {
      font-weight: 400;
      font-size: 13px;
      font-family: 'Poppins';
    }

    .css-6qwycv-control {
      // background-color: #FFF;
    }
  }

  .coupon_modal_date_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;

    .coupon_modal_date_field_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .coupon_modal_date_field_heading {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #B2B3BD;
    }
    .input {
      background-color: #fff !important;
    }

    .input_container {
      background-color: #fff !important;
    }

    .coupon_modal_date_field {
      width: 160px;
    }

  }
  .coupon_modal_button_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;

.coupon_modal_button_flex_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
  }
}

.coupon_details_wrapper {
  width: 100% !important;
  height: 70%;
  border-radius: 24px;
  padding: 30px;

  .coupon_details_heading {
    //styleName: headline/h5 - 24;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    color: #A1342D
  }

  .coupon_details_flex_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 24px;
    gap: 50px;
  }

  .coupon_details_element_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;

    .coupon_details_field_content {
      //styleName: caption/caption 2 - 12;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #B2B3BD;

    }

    .coupon_details_field_text {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0px;
      color: #11142D;
      margin-top: 8px;

    }

    .coupon_details_field_space {
      margin-top: 8px;
    }


  }
}



