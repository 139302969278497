@import '../../../index.scss';
.cards_wrapper {
  padding: 10px;
  background-color: $secondaryBtnColor;
  border-radius: 16px;
  width: 100%;
  .cards_container {
    width: 100%;
    background-color: $secondaryBtnColor;
    border-radius: 16px;
    padding: 10px 0;
    height: 250px;
    overflow-y: scroll;
    .title {
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      padding: 20px;
    }
    .decsription {
      width: 90%;
      //font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding: 20px;
      height: 70%;
      overflow-y: scroll;
    }

    .cards_container_footer {
      display: flex;
      padding-left: 20px;
      padding-top: 10px;
      height: 30%;
      align-items: center;
      .cards_info_container {
        width: 55%;
        .cards_name {
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 500;
        }
        .cards_time {
          //font-family: 'Inter';
          font-size: 14px;
          font-weight: 400;
        }
      }
      .nav {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        .edit_icon,
        .trash_icon {
          margin: 10px;
        }
      }
    }
  }
}
