@import '../../../../index.scss';
.admin_header {
  width: 96%;
  padding: 25px 0 0 0;
  margin: 0px auto 25px auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 12%;
  .title_name {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    width: 43%;
  }
  .profile_wrapper {
    display: flex;
    justify-content: space-between;
    width: 57%;
  }
  .search_content {
    width: 324px;
  }

  .icon_panel {
    display: flex;
  }
  .icon_panel img {
    width: 40px;
    margin: 0 10px;
  }
  .date_picker_container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
  }
}
