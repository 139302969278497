@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400;1,500;1,600&display=swap');
@import '../../index.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login_container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  .login_img{
    // display:none;
    display: flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:250px;
    padding-top: 100px;
    img{
    width:150px;
    height:130px;
    }
  }
  
  .login_wrapper {
    padding: 0 25px;
    width: 100%;
  }
  .login_heading {
    margin-top: 18px;
    color: #0f182e;
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 49px;
    letter-spacing: 0em;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .login_input_field_wrapper {
    margin-top: 10px;
  }
  .login_input_field_content {
    margin-top: 25px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: $textContentColor;
  }

  .login_input_field {
    margin-top: 25px;
  }

  .login_button {
    margin-top: 30px;
    margin-bottom:30px;
  
  }
  .login_screen_skip_wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .login_screen_skip_flex_wrapper{
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  .login_screen_skip_icon{
    margin-top:6px ;
  }
  .login_screen_skip_content {
    color: $primaryButtonColor
  }
  .login_screen_checkbox_conatiner{
    display: flex;
    margin-top: 15px;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    .referral_code{
      margin-top: -3px;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #768089;
    }
  }
}
