$primaryDarkColor: #10213f;
$primaryLightColor: #586479;
$primaryDarkText: #0b162b;
$primaryLightText: #8e8e93;
$secondaryDarkColor: #f16722;
$secondaryLightColor: #dcdcdc;
$secondaryDarkText: #ff754c;
$secondaryLightText: #8e8e93;
$background: #f7f7f7;
$secondaryBackground: #f0f5ff;
$inputBackground: #f7f7f7;
$buttonTextColor: #ffffff;
$outline: #f1f1f4;
$focusOutLine: #cfcfcf;
$otpOutLine: #ebebeb;
$cancel: #e73f3f;
$primaryBtnColor: #1b1d21;
$secondaryBtnColor: #e4e4e4;
$gradientButtonColor: linear-gradient(
  100.12deg,
  #27c69e -22.5%,
  #1c7bdf 122.07%
);
$inputHeadingColor: #11142d;
// $inputHeadingColor: #808191;
$primaryLinkColor: #1f75ec;
$tabInactiveColor: #efefef;
$inputPlaceholder: #808191;
$inputTextColor: #11142d;
$activeTabColor: #1d7bdf;
$socialBtnColor: #0bb78c;
$checkboxActive: #7fba7a;
$iconBadgeBackground: #ff754c;
$primaryCardColor: #f0f3f6;
$primaryBorderColor: rgba(128, 129, 145, 0.6);
$secondaryBorderColor: #1c7bdf;
$primaryNumberBg: rgba(28, 123, 212, 0.2);
$error: #ea5224;
// pleat
$textContentColor: #768089;
$labelColor: #848d94;
$primaryButtonColor: #9C312B;
$borderColor: #e8e8e8;
$contentDarkColor: #1d1d1d;
$contentLightColor: #4f4f4f;
$iconBackground: #eff1f3;
$iconBorderColor: #dedede;
$statusTextColor: #0f182e;
$timeTextColor: #9299a0;
$viewTextColor: #3db2ff;
$notifyBackground: #fafafa;
$orderButtonColor: #fcfbff;
$dashedBorderColor: #a4b0be;
$primaryBackgroundColor: #f4f6f8;
$addButtonTextColor: #3db2ff;
$navButtonColor: #dedede;
$requestIdColor: #333333;
$rejectButtonColor: #eaeaea;
$darkColor: #000000;
$borderColor: #e8e8e8;
$labelColor: #848d94;
$secondaryButtonColor: #f0f9ff;
$primaryGrayColor:#898989;
$primaryGreenBorder:#00d715;
$primaryGreenBackground:#f4fff9;
$primaryYellowBorder:#feb850;
$primaryYellowBackground:#fff9f9;
$primaryDashedBackground:#d4d4d4;
