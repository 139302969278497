@import '../../../../index.scss';
.list_component_container {
  height: 91%;
  padding-bottom: 20px;
}

.list_component_header {
  // height: 60px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 13px 0;
  color: black;
  border-bottom: 1px solid $inputBackground;
  height: 9%;
}
.no_data_found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  color: #978e8e;
  font-style: normal;
}
.list_component_row {
  // height: 60px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0;
  color: black;
  border-bottom: 1px solid $inputBackground;
}
.list_component_index_wrapper {
  width: 100px;
}
.list_component_index {
  width: 50px;
  padding: 10px;
}

.list_component_name_wrapper {
  // width: 40%;
  display: flex;
  justify-content: flex-start;
  padding: 0 5px;
}
.list_component_header_name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  text-transform: capitalize;

  // padding-right: 20px;
}
.list_component_name {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 3px auto;
  text-transform: capitalize;
}
.list_component_paid_active {
  padding: 8px 12px;
  background: rgba(109, 174, 67, 0.1);
  color: #6dae43;
  border-radius: 4px;
}

.list_component_paid_active::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #6dae43;
  margin-right: 10px;
}

.list_component_unpaid_active {
  padding: 10px 15px;
  background: rgba(244, 81, 30, 0.1);
  color: #f4511e;
  border-radius: 4px;
}

.list_component_unpaid_active::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #f4511e;
  margin-right: 10px;
}
.list_component_accepted_active {
  padding: 8px 15px;
  background: rgba(67, 174, 129, 0.1);
  color: #43ae81;
  border-radius: 4px;
}

.list_component_accepted_active::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #43ae81;
  margin-right: 10px;
}
.list_component_onmyway_active {
  padding: 8px 12px;
  background: rgba(253, 159, 0, 0.1);
  color: #fd9f00;
  border-radius: 4px;
}

.list_component_onmyway_active::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #fd9f00;
  margin-right: 10px;
}
.list_component_queued_active {
  padding: 8px 12px;
  background: rgba(251, 192, 45, 0.1);
  color: #fbc02d;
  border-radius: 4px;
}

.list_component_queued_active::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #fbc02d;
  margin-right: 10px;
}

.list_component_card {
  height: 88%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 12%;
}
.icon_wrapper img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.icon_wrapper_active {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 14%;
}
.icon_wrapper_active img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.action_button {
  /* background: #9C312B; */

  width: 12%;
  display: flex;
  justify-content: center;
}
.action_icon_button {
  width: 16%;
  display: flex;
  justify-content: space-evenly;
}
.action_icon_button img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
