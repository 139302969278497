@import '../../../index.scss';

.tab_wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  gap:10px;
  align-items: center;
  border-radius: 40px;
  // background-color: $inputBackground;
  padding: 0 5px;
  .tab_container {
    width: 30%;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55%;
    padding: 10px;
    //font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    .tab_text {
      //font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4f4f4f;
    }
  }
}
.tab_active {
  background: $primaryButtonColor;
}
.active_text {
  color: $buttonTextColor !important;
  //font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}
.tab_container:hover {
  cursor: pointer;
  transition: all 0.3s ease;
}
