@import '../../../index.scss';
.input_container {
  display: flex;
  flex-direction: row;
  // background-color: $inputBackground;
  outline: 2px solid $inputBackground;
  border-radius: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px;
  width: 100%;
  .input {
    // background-color: $inputBackground;
    // height: 50px;
    border: 0px;
    border-radius: 8px;
    outline: 0px;
    padding: 10px;
    font-size: 14px;
    color: $inputTextColor;
  }
  .input_icon {
    width: 15px;
    height: 15px;
  }
}
.focus {
  outline: 2px solid #1d7bdf;
}
.set_bg {
  background-color: $buttonTextColor !important;
  color: $inputTextColor !important;
  // font-weight: 600;
}
.input_field_error {
  margin-top: 10px;
  //font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  color: $cancel;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.end_text_wrapper {
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 400;
  color: $primaryButtonColor;
  cursor: pointer;
}
input::placeholder {
  color: $primaryGrayColor;
  font-weight: 400;
  font-size: 16px;
}
