@import '../../../index.scss';

.tag_container {
  border-radius: 12px;
  background: $gradientButtonColor;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  min-width: 150px;
  height: 36px;
  margin: 10px;
  align-items: center;
  .tag_user_details {
    display: flex;
    justify-content: center;
    align-items: center;
    .tag_profile {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      margin-right: 10px;
    }
    .tag_text {
      //font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: $buttonTextColor;
    }
  }
  .tag_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
}
