.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html,
body {
  font-family: 'Poppins' !important;
  overflow: hidden;
  background-color: #fff;
}
// html {
//   background-color: #282c34;
// }

// @media screen and (min-width: 500px) {
// body {
//   max-width: 360px !important;
//   margin: auto;
//   height: 100vh;
//   position: relative;
// }
// }
@media screen and (min-width: 500px) {
  .body_class_add {
    max-width: 360px !important;
    margin: auto;
    height: 100vh;
    position: relative;
  }

  .footer_container {
    max-width: 360px !important;
  }

  :root {
    --rsbs-max-w: 360px !important;
  }
}

.footer_container {
  max-width: 100%;
}
:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: 100%;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 16px;
  // --rsbs-m:auto
}
// }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: url(assets/fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url(assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url(assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url(assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url(assets/fonts/Inter-Thin.ttf);
}
@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url(assets/fonts/Inter-Thin.ttf);
}
@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url(assets/fonts/Inter-ExtraLight.ttf);
}
@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url(assets/fonts/Inter-Light.ttf);
}
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(assets/fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url(assets//fonts//OpenSans-Regular.ttf);
}
::-webkit-scrollbar {
  display: none;
}
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
