@import '../../../index.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400;1,500;1,600&display=swap');
.error_message {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 5px;
  color: #ff7373;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.focus_input{
  border: 3px solid $borderColor !important;
}

.material-textfield {
  position: relative;
  width: 100%;
}
input::placeholder {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #848d94;
}
.error{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #FE5050;
}
.outline_input_flex_wrapper_error input {
  font-size: 1rem;
  outline: none;
  border: none;
  font-family: 'Open Sans', sans-serif;
  border-radius: 8px;
  padding: 1rem 1.2rem;
  color: gray;
  transition: 0.1s ease-out;
  width: 100%;
}

.input_icon {
  width: 18px !important ;
  height: 18px !important ;
}
.outline_input_flex_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid $borderColor;
  border-radius: 10px;
}

.outline_input_flex_wrapper input {
  font-size: 1rem;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  border: none;
  border-radius: 8px;
  padding: 1rem 1.2rem;
  color: gray;
  transition: 0.1s ease-out;
  width: 100%;
}


label {
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}


.outline_input_flex_wrapper input {
  font-size: 1rem;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  border: none;
  border-radius: 8px;
  padding: 1rem 1.2rem;
  color: gray;
  transition: 0.1s ease-out;
  width: 100%;
}

input:focus {
  border: none;
  color: $inputTextColor;
}

.outline_input_flex_wrapper input:focus + label {
  color: $textContentColor;
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
}

.outline_input_flex_wrapper input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
}

.outline_input_flex_wrapper input:not(:focus)::placeholder {
  opacity: 0;
}
