@import '../../../index.scss';

.notification_container {
  width: 100%;
  height: 100%;
  display: flex;

  .sidebar-header {
    width: 20%;
  }

  .notification_wrapper {
    width: 80%;
    background: #f6f6f6;
    height: 100vh;
  }

  .notification_table_container {
    width: 93%;
    margin: 0 15px 15px 15px;
    background: #ffff;
    border-radius: 12px;
    padding: 20px 30px;
  }

  .notification_content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 5px 10px;
  }

  .notification_header_content {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
  }

  .notification_content {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: #b2b3bd;
    margin: 15px 0;
  }

  .notification_submit {
    margin: 20px 0;
  }

    .notification_list_wrapper {
      margin: 30px;
      padding: 30px;
      margin-top: 40px;
      background-color: #FFFFFF;
      border-radius: 24px;
      height: 75%;
  
      .notification_tab_list_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 10px;
        width: 100%;
  
        .notification_tab_list_active {
          font-family: 'Poppins';
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0px;
          color: #A1342D;
          width: 50%;
        }
  
        .notification_tab_list_in_active {
          font-family: 'Poppins';
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0px;
          color: #d3d3d3;
          width: 50%;
        }
      }
  
      .notification_tab_list_line {
        display: flex;
        align-items: center;
  
        .notification_tab_list_line_active {
          display: flex;
          border: 0.5px solid #A1342D;
          width: 50%;
          margin-top: 15px;
        }
  
        .notification_tab_list_line_in_active {
          display: flex;
          border: 0.5px solid #d3d3d3;
          width: 50%;
          margin-top: 15px;
        }
      }
  
      .notification_modal {
        margin-top: 24px;
        background-color: #A1342D;
        border-radius: 12px;
        width: 190px;
        height: 44px;
        margin-left: auto;
        display: flex;
        justify-content: flex-start;
        padding: 0px 20px;
        align-items: center;
        cursor: pointer;
  
        .notification_modal_button_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
        }
        .notification_send_button_content {
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
      }
      .schedule_notification_modal {
         width: 150px;
         padding: 0px 15px;
      }
  
      .notification_table_wrapper {
        overflow-x: hidden;
        overflow-y: scroll;
        margin-top: 15px;
        margin-left: -8px;
        height: 90%;
  
      }
  
      .notification_primary_button {
        height: 40px;
      }
    }

}