@import '../../index.scss';

.current_order_card_container {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    width: 100% !important;
    margin: 0 auto;
    height: 340px;
    position: relative;
    // background-color: pink;

    .swiper_wrap {
        position: relative;
        height: 50%;

        .saree_image {
            height: 160px;
            object-fit: cover;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        .swiper-pagination-bullet {
            position: relative;
            width: 8px;
            height: 8px;
            border-radius: 8/2;
            background-color: $buttonTextColor;
            bottom: 12px;
        }
    }

    .address_wrap {
        width: 88%;
        height: 33%;
        background-color: $primaryBackgroundColor;
        padding: 12px;
        border-radius: 20px;
        margin: 0 auto;
        position: absolute;
        top: 140px;
        z-index: 999;
        left: 0;
        right: 0;
        padding-bottom: 10px;

        .request-id_quantity_wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .request-id_wrap {
                font-size: 16px;
                font-weight: 600;
                color: $requestIdColor;
            }

            .quantity_wrap {
                font-size: 10px;
                font-weight: 400;
                color: $contentLightColor;
            }
        }

        .address {
            font-size: 10px;
            font-weight: 400;
            color: $contentLightColor;
            padding-top: 6px;
            padding-bottom: 8px;
        }

    .payment_wrap {
        display: flex;
        flex-direction: row;
        // background-color: green;
        align-items: center;

        .lightning_icon {
            width: 15px;
            height: 15px;
            margin-bottom: 2px;
        }

        .pay_on_delivery {
            font-size: 10px;
            font-weight: 500;
            color: $requestIdColor;
            margin-left: 6px;
        }
    }

    .total_wrap {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        justify-content: space-between;
        align-items: center;

        .total_text_wrap {
            display: flex;
            flex-direction: row;
            align-items: center;

            .total_text {
                font-size: 10px;
                font-weight: 400;
                color: $contentLightColor;
            }

            .value {
                font-size: 14px;
                font-weight: 500;
                color: $contentLightColor;
                margin-left: 5px;
            }
        }

        .reject_accept_buttons_wrap {
            display: flex;
            flex-direction: row;
        }

        .reject_button_wrap {
            height: 25px;
            background-color: $rejectButtonColor;
            border-radius: 40px;
        }

        .accept_button_wrap {
            height: 25px;
            background-color: $primaryButtonColor;
            border-radius: 40px;
            margin-left: 10px;
        }
    }
    .stepper_wrap{
        background-color: red;
        padding: 20px;
    }

    }
}