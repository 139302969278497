@import '../../../index.scss';

textarea {
  width: 100%;
  min-height: 72px;
  padding: 14px;
  border: 1px solid $borderColor;
  border-radius: 8px;
  font-size: 13px;
  // color: $inputPlaceholder;
  font-family: 'Poppins';
  // background-color: $inputBackground;
  resize: none;
  outline: none;
}
.text_area_active {
  font-weight: 600;
  color: black !important;
  background-color: $buttonTextColor;
  font-size: 14px;
  border: 1px solid $primaryButtonColor;
}
