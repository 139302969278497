@import '../../../index.scss';
.addsaree_container {
  margin: 13px 0px;
  .addsaree_remove_text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 8px;
    color: $primaryButtonColor;
    cursor: pointer;
  }
  .addsaree_wrapper {
    background: $primaryBackgroundColor;
    border-radius: 16px;
    padding: 10px;
    .addsaree_top_wrapper {
      border-bottom: 1px solid $borderColor;
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      overflow-x: auto;
    }
    .addsaree_camera_container {
      // overflow-x: auto;
      gap: 15px;
    }
    .upload_saree_image_wrapper {
      display: flex;
      gap: 10px;
      // overflow-x: auto;
    }
    .upload_image_position {
      position: relative;
    }
    .upload_saree_image {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      margin-right: 4px;
    }
    .cancel_button {
      position: absolute;
      top: 0;
      right: -5px;
      z-index: 9999;
    }
    .addsaree_bottom_wrapper {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      .addsaree_saree_count_container {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
      }
      .addsaree_saree_price_container {
        display: flex;
        align-items: center;
        .addsaree_price_currency {
          font-family: 'Poppins';
          font-size: 10px;
          font-weight: 400;
          padding-right: 3px;
        }
        .addsaree_price_value {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
